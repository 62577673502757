import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Theme,
  Typography,
} from "@material-ui/core";
import styles from "./index.module.css";
import { createStyles, withStyles } from '@material-ui/styles';
import { Field, FieldProps, Formik } from 'formik';
import authApi from "./api/AuthApi"
import { useMutation } from '../../../utils/axios-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { addUserDataAction, updateAuthStateAction } from '../../../actions/auth/actions';
import { AccountType, AuthStates, IUser, UserRole } from '../../../reducers/auth/types';
import { IAppState } from '../../../store';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useLazyQuery } from '@apollo/client';
import { GET_USER } from '../../../graphql/user/getUser';

const StyledInputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
    error: {
      '& input': {
        borderColor: "#c71717",
        color: "#c71717"
      },
      '& textarea': {
        borderColor: "#c71717",
        color: "#c71717"
      },
      '& .MuiSelect-select': {
        borderColor: "#c71717",
        color: "#c71717",
        backgroundColor: '#fff !important',
      }
    },
    input: {
      borderRadius: "4px !important",
      position: 'relative',
      backgroundColor: "#fff",
      border: '1px solid var(--accent-shade-d)',
      fontSize: 16,
      padding: '12px 12px',
      minHeight: 'initial'
    },
  }),
)(InputBase);

interface ISignInProps {
  onAuthorize: (result: string, user?: any, token?: string) => void;
}

export const SignIn: React.FC<ISignInProps> = (props) => {
  const dispatch = useDispatch();
  const authState = useSelector((state: IAppState) => state.authReducer);

  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);

  const { mutate: signIn, error, loading } = useMutation<any, any>(authApi.signin, {
    onSuccess: (res, variables) => {
      if (res.success && !res.token) {
        props.onAuthorize("OTP_SENT", { email: variables.email });
      }
      if (res.success && res.token) {
        props.onAuthorize("SELECT_ACCOUNT", res.user, res.token)
      }
    }
  });

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  return (
    <div className={styles.card}>
      {authState.message && <div className={styles.infoBox}>{authState.message}</div>}
      <div className={styles.top}>
        <div style={{ width: "100%" }}>
          <h3 style={{ margin: "10px 0" }}>Sign in</h3>
          <p style={{ opacity: 0.6 }}>Enter your email and password to login</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={schema}
          onSubmit={values => {
            signIn(values)
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} className="text-left">
              <Field name="email">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ display: "flex", justifyContent: "space-beetween" }}>
                      <span>Email*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Enter your email here"
                      fullWidth
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Field name="password">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ marginTop: 20, display: "flex", justifyContent: "space-beetween" }}>
                      <span>Password*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Password"
                      fullWidth
                      type="password"
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={{ background: "var(--primary-color)", marginTop: 20, boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                {loading ? <CircularProgress style={{ color: "#fff" }} size={20} thickness={5} /> : <span>Sign in &#8594;</span>}
              </Button>
              <div className={styles.bottomLinks}>
                <p onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.RESET_PASSWORD }))}>Forgot password?</p>
                {(!website.organisation.closeGroupSharingEnabled || (website.organisation.closeGroupSharingEnabled && !website?.disableSelfRegistration)) && 
                  <p onClick={() =>
                    website.enableBusinessCustomerSignup ?
                      dispatch(updateAuthStateAction({ state: AuthStates.CUSTOMER_SELECTION })) :
                      dispatch(updateAuthStateAction({ state: AuthStates.SIGN_UP }))}
                    style={{ color: "var(--theme-accent", margin: "16px 0 10px 0", cursor: "pointer" }}>
                    Register Now
                  </p>
                }
              </div>
              {error ? (
                <div className={styles.errorBox}>{error.response?.data.message}</div>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
};

interface IResetPasswordProps {
  onResetPasswordInitiate: (user: any) => void;
}

export const ResetPassword: React.FC<IResetPasswordProps> = (props) => {
  const dispatch = useDispatch()

  const { mutate: passwordResetInitiate, error, loading } = useMutation<any, any>(authApi.forgotPassword, {
    onSuccess: (res, variables) => {
      props.onResetPasswordInitiate({ email: variables.email });
    }
  });

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <div>
          <h3 style={{ margin: "10px 0" }}>Password reset</h3>
          <p style={{ opacity: 0.6 }}>You will receive an OTP for password reset on your email</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={schema}
          onSubmit={values => {
            passwordResetInitiate(values)
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} className="text-left">
              <Field name="email">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ display: "flex", justifyContent: "space-beetween" }}>
                      <span>Email*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Enter your email here"
                      fullWidth
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={{ background: "var(--primary-color)", marginTop: 20, boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                {loading ? <CircularProgress color="inherit" size={20} thickness={5} /> : <span>Send OTP &#8594;</span>}
              </Button>
              <p onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))} style={{ color: "var(--theme-accent", margin: "16px 0 10px 0", cursor: "pointer" }}>Login instead?</p>
              {error ? (
                <div className={styles.errorBox}>{error.response?.data.message}</div>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
};

interface IVerifyOTPProps {
  email: string;
  onVerifyOTP: (user: any, token: string) => void;
}

export const VerifyOTP: React.FC<IVerifyOTPProps> = (props) => {
  const dispatch = useDispatch();
  const authState = useSelector((state: IAppState) => state.authReducer);

  const { mutate: validateOtp, error, loading } = useMutation<any, any>(authApi.validateOtp, {
    onSuccess: (res, variables) => {
      if (res.success && res.data.token) {
        props.onVerifyOTP(res.data.user, res.data.token);
      }
    }
  });

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    otp: Yup.string().required(),
  });

  return (
    <div className={styles.card}>
      {authState.message && <div className={styles.infoBox}>{authState.message}</div>}
      <div className={styles.top}>
        <div>
          <div style={{ margin: "0 10px 0 -20px" }}>
            <IconButton onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))} aria-label="delete" size="small" color="default" edge="start">
              <KeyboardBackspaceIcon style={{ fontSize: 24 }} />
            </IconButton>
          </div>
        </div>
        <div>
          <h3 style={{ margin: "10px 0" }}>Verify OTP</h3>
          <p style={{ opacity: 0.6 }}>The OTP has been sent to your email</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <Formik
          initialValues={{
            email: props.email,
            otp: ""
          }}
          validationSchema={schema}
          onSubmit={values => {
            validateOtp(values)
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} className="text-left">
              <Field name="otp">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ marginTop: 20, display: "flex", justifyContent: "space-beetween" }}>
                      <span>Enter OTP*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Enter OTP here"
                      fullWidth
                      type="text"
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={{ background: "var(--primary-color)", marginTop: 20, boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                {loading ? <CircularProgress color="inherit" size={20} thickness={5} /> : <span>Verify &#8594;</span>}
              </Button>
              {error ? (
                <div className={styles.errorBox}>{error.response?.data.message}</div>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
};

interface ISetPasswordProps {
  email: string;
  token: string;
  onSetPassword: (user: any, token: string) => void;
}

export const SetPassword: React.FC<ISetPasswordProps> = (props) => {
  const dispatch = useDispatch()

  const { mutate: setPassword, error, loading } = useMutation(authApi.setPassword, {
    onSuccess: (res) => {
      props.onSetPassword(res.user, res.token);
    },
  });

  const schema = Yup.object().shape({
    password: Yup.string().required("Required*")
      .min(8, 'password length must be >=8')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    repeatPassword: Yup.string().required("Required*")
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <div>
          <div style={{ margin: "0 10px 0 -20px" }}>
            <IconButton onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))} aria-label="delete" size="small" color="default" edge="start">
              <KeyboardBackspaceIcon style={{ fontSize: 24 }} />
            </IconButton>
          </div>
        </div>
        <div>
          <h3 style={{ margin: "10px 0" }}>Set new password</h3>
        </div>
      </div>
      <div className={styles.bottom}>
        <Formik
          initialValues={{
            password: "",
            repeatPassword: ""
          }}
          validationSchema={schema}
          onSubmit={values => {
            setPassword({
              email: props.email,
              token: props.token,
              password: values.password
            })
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} className="text-left">
              <Field name="password">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ display: "flex", justifyContent: "space-beetween" }}>
                      <span>New Password*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Enter password here"
                      fullWidth
                      type="password"
                      error={touched && Boolean(error)}
                    />
                    {field.value && (
                      <ul style={{ marginTop: 5, paddingLeft: 20 }}>
                        {field.value.length < 8 ? (
                          <li className={styles.invalid}><b>Must be 8 characters long</b></li>
                        ) : (
                          <li className={styles.valid}><b>Must be 8 characters long</b></li>
                        )}
                        <li className={/[A-Z]/.test(field.value) ? styles.valid : styles.invalid}><b>At least one Uppercase letter</b></li>
                        <li className={/[a-z]/.test(field.value) ? styles.valid : styles.invalid}><b>At least one Lowercase letter</b></li>
                        <li className={/[0-9]/.test(field.value) ? styles.valid : styles.invalid}><b>At least one Numerical character</b></li>
                        <li className={/[!@#$%^&*(),.?":{}|<>]/.test(field.value) ? styles.valid : styles.invalid}><b>At least one Special character</b></li>
                      </ul>
                    )}
                  </>
                )}
              </Field>
              <Field name="repeatPassword">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ marginTop: 20, display: "flex", justifyContent: "space-beetween" }}>
                      <span>Confirm Password*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>{error}</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Re-enter password here"
                      fullWidth
                      type="password"
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={{ background: "var(--primary-color)", marginTop: 20, boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                {loading ? <CircularProgress color="inherit" size={20} thickness={5} /> : <span>Confirm &#8594;</span>}
              </Button>
              {error ? (
                <div className={styles.errorBox}>{error.response?.data.message}</div>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
};

interface ISetPasswordProps {
  email: string;
  token: string;
  onSetPassword: (user: any, token: string) => void;
}

export const SelectAccount: React.FC<{ onSignIn: (user: IUser, accountType: AccountType) => void }> = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state: IAppState) => state.authReducer.authUser);
  const [user, setUser] = useState<IUser>();

  const [getUser, { loading, data, error }] = useLazyQuery(GET_USER, {
    onCompleted: ({ user }: { user: IUser }) => {
      if (user.customer && user.businesscustomers) {
        setUser(user);
      }
      else {
        const accountType = user.customer ? AccountType.INDIVIDUAL : AccountType.BUSINESS
        props.onSignIn(user, accountType);
      }
      // props.onSignIn();
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (authUser) {
      getUser({
        variables: {
          id: authUser._id,
        },
      });
    }
  }, []);

  const setAccount = (user: IUser, business?: boolean) => {
    const _user = {
      ...user,
      businesscustomers: business ? user.businesscustomers : undefined
    }
    props.onSignIn(_user, business ? AccountType.BUSINESS : AccountType.INDIVIDUAL);
  }

  return (
    <div className={styles.card}>
      {loading ? <div className={styles.top}>
        <div style={{ width: 300, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress size={24} thickness={5} />
        </div>
      </div> : user ? <>
        <div className={styles.top}>
          <div style={{ width: "100%" }}>
            <h3 style={{ margin: "10px 0" }}>Sign in as</h3>
            <p style={{ opacity: 0.6 }}>Select an account to proceed</p>
          </div>
        </div>
        <div className={styles.bottom}>
          {user.customer && <div onClick={() => { setAccount(user) }} className={`${styles.payAmoutButton} bold padding-top`}>
            <Typography variant="h4" className="bold flex space-between">
              <span style={{ paddingRight: 40 }}>{user.customer.firstName} {user.customer.lastName}</span>
            </Typography>
            <Typography variant="body1" className="text-grey flex space-between cross-center">
              <span style={{ paddingRight: 50 }}>Personal account</span>
              <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
            </Typography>
          </div>}
          {(user.businesscustomers && (user.customerId ? user.businesscustomers.approvedDrivers.some(item => item.id === user.customerId) : true)) && <div onClick={() => { setAccount(user, true) }} className={`${styles.payAmoutButton} bold padding-top`}>
            <Typography variant="h4" className="bold flex space-between">
              <span style={{ paddingRight: 40 }}>{user.businesscustomers.businessName}</span>
            </Typography>
            <Typography variant="body1" className="text-grey flex space-between cross-center">
              <span style={{ paddingRight: 50 }}>{user.role === UserRole.ADMIN_USER ? "As bussiness customer" : "As driver"}</span>
              <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
            </Typography>
          </div>}
        </div>
      </> : null}
    </div>
  )
};
