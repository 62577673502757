import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import { TestDriveBookingStepper } from "../TestDriveStepper";
import { VehicleSelectionCard } from "./VehicleSelectCard";
import { useDispatch, useSelector } from "react-redux";
import { updateTestDriveBooking } from "../../../../../actions/testDriveBooking/actions";
import { TestDriveBookingSteps } from "../../../../../reducers/testDriveBooking/types";
import { IAppState } from "../../../../../store";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useSnackBar } from "../../../../SnackBarContext/SnackBarContext";
import { GET_GROUPED_VEHICLES_FROM_GROUP } from "../../../../../graphql/bookings/getGroupedVehiclesFromGroup";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { ApolloError } from "apollo-boost";
import { SnackBarVariant } from "../../../../SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../utils";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  text: { marginTop: theme.spacing(2), marginBottom: theme.spacing(0.5) },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-input": { cursor: "pointer" },
  },
  filter: {
    minWidth: 250,
  },
}));

export const VehicleSelection: React.FC = () => {
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const classes = useStyles();
  const [vehicles, setVehciles] = useState<any>([]);

  const testDriveBookingState = useSelector(
    (state: IAppState) => state.testDriveBookingReducer
  );

  const appSetting = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );

  const [getGroupedVehicles, { loading: vehiclesLoading }] = useLazyQuery(
    GET_GROUPED_VEHICLES_FROM_GROUP,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.getGroupedVehiclesFromGroup) {
          setVehciles(data.getGroupedVehiclesFromGroup);
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR,
        });
      },
    }
  );

  useEffect(() => {
    if (
      testDriveBookingState.branchId &&
      testDriveBookingState.vehicleGroupId &&
      appSetting.id
    ) {
      getGroupedVehicles({
        variables: {
          branchId: testDriveBookingState.branchId,
          vehicleGroupId: testDriveBookingState.vehicleGroupId,
          appSettingId: appSetting.id,
        },
      });
    }
  }, [testDriveBookingState, appSetting, getGroupedVehicles]);

  const nextStep = (
    vehicleName: string,
    vehicleIds: string[],
    vehicleImage: string
  ) => {
    dispatch(
      updateTestDriveBooking({
        ...testDriveBookingState,
        availableVehicleIds: vehicleIds,
        stepperData: {
          ...testDriveBookingState.stepperData,
          model: vehicleName,
        },
        vehicleImage,
        currentStep: TestDriveBookingSteps.VEHICLE_CONFIRMATION,
      })
    );
  };

  const prevStep = () => {
    dispatch(
      updateTestDriveBooking({
        ...testDriveBookingState,
        currentStep: TestDriveBookingSteps.BRANCH_SELECTION,
      })
    );
  };

  return (
    <Grid item container spacing={2}>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        alignItems="flex-start"
        justify="flex-start"
      >
        <Grid item>
          <IconButton aria-label="previous-step" onClick={prevStep}>
            <ArrowBackIcon style={{ padding: 0 }} />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h1" component={"h1"}>
            Select A Car
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TestDriveBookingStepper />
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item container xs={10} spacing={2}>
          {vehiclesLoading &&
            new Array(6).fill(1).map(() => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={200}
                />
                <Skeleton
                  animation="wave"
                  height={50}
                  width="80%"
                  className={classes.title}
                />
                <Skeleton variant="rect" width={95} height={42} />
              </Grid>
            ))}
          {!vehiclesLoading &&
            vehicles.map((vehicleData: any) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VehicleSelectionCard
                  title={vehicleData.vehicleName}
                  imageUrl={vehicleData.imageUrl}
                  onClick={() =>
                    nextStep(
                      vehicleData.vehicleName,
                      vehicleData.vehicleIds,
                      vehicleData.imageUrl
                    )
                  }
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
