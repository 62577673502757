import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GoogleMapReact from "google-map-react";
import styles from './index.module.css';
import { VehicleMarker, EventMarker, EndPointMarker } from "./Marker";
import { TelematicsView, ITelematicsData, eventKeysMap, ISmartcarData } from './TelematicsView';
import { QueryBox, IVehicleTelematics } from './QueryBox';
import dayjs from 'dayjs';


const initialLocationLatLng = {
  lng: -4.3728844,
  lat: 55.855573
};

const MapContainer = (props: any) => {
  const mapRef = useRef<{ map: any, maps: any }>();
  const routePloylineRef = useRef<any>();
  const [vehicles, setVehicles] = useState<IVehicleTelematics[]>([]);
  const [markedVehicle, setMarkedVehicle] = useState<IVehicleTelematics>();
  const [infoState, setInfoState] = useState<{ open: boolean, vehicle?: IVehicleTelematics }>({ open: false, vehicle: undefined });
  const [telematicsData, setTelematicsData] = useState<ITelematicsData | undefined>();
  const [smartcarData, setSmartcarData] = useState<ISmartcarData[] | undefined>([]);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);

  const getCoords = () => {
    let tempcoords: any[] = [];
    if (telematicsData?.mapRoutesData) {
      const data = telematicsData.mapRoutesData
      data.latLong.map((latlong, i) => {
        const latlongArr = latlong?.split(",")
        if(!(latlongArr.length < 2)){
          tempcoords.push({ lat: parseFloat(latlongArr[0]), lng: parseFloat(latlongArr[1]) })
        }
      })
    }
    return tempcoords
  }

  const renderVehicleMarkers = () => {
    let tempVehicles: IVehicleTelematics[] = []
    if (infoState.open && infoState.vehicle) {
      tempVehicles = [infoState.vehicle]
    }
    else if (vehicles) {
      tempVehicles = vehicles
    }
    let markers: JSX.Element[] = []
    for (var index = 0; index < tempVehicles.length; index++) {
      const vehicle = tempVehicles[index];
      const isMarked = vehicle.id === markedVehicle?.id;
      (vehicle.lat && vehicle.lng) && markers.push(<VehicleMarker
        key={index}
        lat={vehicle.lat}
        lng={vehicle.lng}
        color={vehicle.id === markedVehicle?.id ? "#007cff" : "black"}
        onClick={() => handleInfoOpen(vehicle)}
        loading={infoState.open && !telematicsData && !smartcarData}
      />)
    }
    markers.length && !infoState.open && setBoundsForVehicles()
    return markers
  }
  const renderEventMarkers = () => {
    if (telematicsData?.mapFlagsData) {
      return telematicsData.mapFlagsData.latLong.map((latLongString, i) => {
        const latlongArr = latLongString.split(",");
        const eventName = telematicsData.mapFlagsData.Key[i];
        const eventTime = telematicsData.mapFlagsData.eventTime[i];
        if (selectedEvents.length && !selectedEvents.includes(eventName) || latlongArr.length < 2) {
          return
        }
        return (
          <EventMarker
            data={{
              time: eventTime,
              name: eventName,
              magnitude: telematicsData.mapFlagsData.magnitude[i],
              speedLimit: telematicsData.mapFlagsData.speedLimit[i]
            }}
            lat={parseFloat(latlongArr[0])}
            lng={parseFloat(latlongArr[1])}
            color={eventKeysMap[eventName]?.color || "#747474"}
            onClick={() => { }}
          />
        )
      })
    }
  }

  const renderRouteEndPoints = () => {
    if (telematicsData) {
      const start = telematicsData?.mapRoutesData?.latLong?.[0]?.split(",")
      const end = telematicsData?.mapRoutesData?.latLong?.[telematicsData.mapRoutesData.latLong.length - 1]?.split(",");
      if(start && end){
        const endPoints = [{ point: "START", color: "#0980FD", coords: start }, { point: "END", color: "#171717", coords: end }];
        return endPoints.map(point => <EndPointMarker
          lat={parseFloat(point.coords[0])}
          lng={parseFloat(point.coords[1])}
          point={point.point}
        />)
      }
      return <></>;
    }
  }

  const setPolylineData = (map: any, maps: any) => {
    if (telematicsData?.mapRoutesData) {
      const trailCoords = getCoords();
      let geodesicPolyline = new maps.Polyline({
        path: trailCoords,
        geodesic: true,
        strokeColor: '#000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      })
      routePloylineRef.current = geodesicPolyline
      geodesicPolyline.setMap(map);
      if (trailCoords.length) {
        const bounds = new maps.LatLngBounds()
        for (let coords of trailCoords) {
          bounds.extend(
            new maps.LatLng(coords.lat, coords.lng)
          )
        }
        map.fitBounds(bounds)
      }
    }
  }

  const handleInfoOpen = useCallback((vehicle: IVehicleTelematics) => {
    if (!infoState.open) {
      setInfoState({
        open: true,
        vehicle
      });
    }
  }, [])

  const setBoundsForVehicles = () => {
    if (mapRef && mapRef.current?.map && mapRef.current.maps && vehicles.length) {
      const bounds = new mapRef.current.maps.LatLngBounds()
      if (markedVehicle && markedVehicle.lat && markedVehicle.lng) {
        bounds.extend(
          new mapRef.current.maps.LatLng(markedVehicle.lat, markedVehicle.lng)
        )
      }
      else {
        for (let vehicle of vehicles) {
          if (vehicle.lat && vehicle.lng) {
            bounds.extend(
              new mapRef.current.maps.LatLng(vehicle.lat, vehicle.lng)
            )
          }
        }
      }
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        var extendPoint1 = new mapRef.current.maps.LatLng(bounds.getNorthEast().lat() + 0.002, bounds.getNorthEast().lng() + 0.002);
        var extendPoint2 = new mapRef.current.maps.LatLng(bounds.getNorthEast().lat() - 0.002, bounds.getNorthEast().lng() - 0.002);
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      mapRef.current.map.fitBounds(bounds)
    }
  }

  useEffect(() => {
    if (mapRef.current) {
      if (mapRef.current.map && mapRef.current.maps) {
        if (infoState.open && telematicsData) {
          if (routePloylineRef.current) {
            routePloylineRef.current.setMap(null);
          }
          setPolylineData(mapRef.current.map, mapRef.current.maps);
        }
        else if (!telematicsData && routePloylineRef.current) {
          routePloylineRef.current.setMap(null);
        }
        else if (!infoState.open && routePloylineRef.current) {
          routePloylineRef.current.setMap(null);
          setBoundsForVehicles()
        }
      }
    }
  }, [infoState.open, telematicsData])

  const handleSetTelematicsData = useCallback((data?: ITelematicsData | undefined) => setTelematicsData(data), []);
  const handleSetSmartcarData = useCallback((data?: ISmartcarData[] | undefined) => setSmartcarData(data), []);
  const handleInfoClose = useCallback(() => setInfoState((prevState) => { return { open: false, vehicle: undefined } }), []);
  const handleSelectEvents = useCallback((selection: string[]) => setSelectedEvents(selection), []);
  const handleSetVehicles = useCallback((vehicles: IVehicleTelematics[]) => setVehicles(vehicles), []);
  const handleSetMarkedVehicle = useCallback((vehicle?: IVehicleTelematics) => setMarkedVehicle(vehicle), []);
  const moveUp = `${130 - props.topBarHeight}px` 
  const mapModuleHeight = `calc(100vh - ${props.topBarHeight}px)`;
  return (
    <div className={styles.mapModule} style={{bottom:moveUp, height: mapModuleHeight}}>
      <div className={styles.mapContainer}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""
          }}
          options={{
            zoomControlOptions: {
              position: 6,
            },
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          }}
          zoom={8}
          defaultCenter={initialLocationLatLng}
          center={initialLocationLatLng}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => mapRef.current = ({ map, maps })}
        >
          {infoState.open && renderRouteEndPoints()}
          {renderVehicleMarkers()}
          {infoState.open && renderEventMarkers()}
        </GoogleMapReact>
      </div>
      <QueryBox
        setVehicles={handleSetVehicles}
        active={!infoState.open}
        setMarkedVehicle={handleSetMarkedVehicle}
        setSmartcarData={handleSetSmartcarData}
      />
      <TelematicsView
        vehicleId={infoState.vehicle?.id || ""}
        esn={infoState.vehicle?.esn || ""}
        data={telematicsData}
        smartcarData={smartcarData}
        active={infoState.open}
        setData={handleSetTelematicsData}
        handleClose={handleInfoClose}
        selectedEvents={selectedEvents}
        handleSelectEvents={handleSelectEvents}
        vehicleLastLocation={infoState.vehicle?.lastLocation || ""}
        lastLocationTime={infoState.vehicle?.lastLocationTime || ""}
      />
    </div>

  )
}
export const MapModule = MapContainer