import { createBrowserHistory } from "history";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import HttpHeader from "../../../header";
import { IAppState } from "../../../store";
import styles from "./index.module.css";
import { TopBar } from '../../TopBar/TopBar';
import { Container, Hidden } from '@material-ui/core';
import SideBar from './Sidebar';
import Tracking from './VehicleTracking';
import Bookings from './Bookings';
import Invoices from './Invoices';
import Vehicles from './Vehicles';
import Account from './account';
import Vehicle from './Vehicle';
import Drivers from './Drivers';
import BookingManagement from './BookingManagement';
import Invoice from './Invoices/Invoice';
import AddDriver from './Drivers/AddDriver';
import CreditNotes from "./CreditNotes";
import CreditNote from "./CreditNotes/CreditNote";

interface IProps {

}

export const history = createBrowserHistory();

const httpHeader = HttpHeader.getInstance();

const CustomerPortal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);

  const logoSize = website.logoSize || 40
  const topBarHeight = logoSize > 50 ? logoSize + 20 : 70

  return (
    <>
      <TopBar
        height={topBarHeight}
        logoSize={logoSize}
        accountScreen
      />
      <div className={styles.container}>
        <Container>
          <div className="flex" style={{ height: "100vh" }}>
            <Hidden xsDown>
              <SideBar />
            </Hidden>
            <div className={styles.rightCol}>
              <Switch>
                <Route path="/account/bookings" exact component={Bookings} />
                <Route path="/account/bookings/:id" component={BookingManagement} />
                <Route path="/account/tracking" component={Tracking} />
                <Route path="/account/invoices" exact component={Invoices} />
                <Route path="/account/invoices/:id" component={Invoice} />
                <Route path="/account/credit-notes" exact component={CreditNotes} />
                <Route path="/account/credit-notes/:id" exact component={CreditNote} />
                <Route path="/account/vehicles" exact component={Vehicles} />
                <Route path="/account/vehicles/:id" component={Vehicle} />
                <Route path="/account/drivers/new" component={AddDriver} />
                <Route path="/account/drivers" component={Drivers} />
                <Route path="/account" exact component={Account} />
              </Switch>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CustomerPortal
