import { FormControl, Grid, MenuItem, TextField as InputField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { DateTime as d } from "luxon";
import React, { useRef } from 'react'
import FlatPickerBar from '../FlatPicker';
import "./index.scss"
import { getLocalizedDateFormat } from '../../utils/localized.syntex';
import { DATE_TYPE } from '../utils';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';

interface IProps {
  date: string;
  dateTitle: string;
  timeTitle: string;
  handleChange(date: IDateTime): void;
  name: string;
  minDate?: string;
  maxDate?: string;
  timeToShow?: 'before' | 'after';
  disabled?: boolean;
  label?: string;
  required?: boolean;
  classId?: string;
}

interface IDateTime {
  date: string;
  time: number
}

export const SimpleDateTimePicker: React.FC<IProps> = (props) => {

  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  
  const { date, dateTitle, timeTitle, handleChange, name, minDate, maxDate, disabled, required } = props;
  const timeRange = useRef<{ fromTime: number[], toTime: number[] }>({ fromTime: [0, 23.45], toTime: [0, 23.45] });

  const setTimeOnDate = (date: d, hour: number) => {
    const formattedDate = date.toFormat("dd/MM/yyyy");
    return d.fromFormat(`${formattedDate} ${numberToHour(hour)}`, "dd/MM/yyyy h:m")
  }

  const numberToHour = (opHr: number) => {
    const minute = parseInt((Math.round(opHr * 100)).toString().substr(-2));
    const hour = Math.floor(opHr);
    return `${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`}`
  }

  const hourToNumber = (hour: string) => {
    const splitted = hour.split(":");
    return parseInt(splitted[0]) + (parseInt(splitted[1]) / 100)
  }


  const getTimesArray = (range: number[]) => {
    let arr = [];
    const maxDateD = maxDate && d.fromISO(maxDate);
    const minDateD = minDate && d.fromISO(minDate);
    const dateD = d.fromISO(date)
    for (let i = range[0]; i <= range[1]; i) {
      const upperRangeCondition = !maxDate || (maxDateD && dateD < maxDateD.startOf("day")) || (maxDateD && dateD.hasSame(maxDateD, "day") && i < hourToNumber(maxDateD.toFormat("HH:mm")));
      const lowerRangeCondition = !minDate || (minDateD && dateD > minDateD.endOf("day")) || (minDateD && dateD.hasSame(minDateD, "day") && i > hourToNumber(minDateD.toFormat("HH:mm")))
      if (lowerRangeCondition && upperRangeCondition) {
        arr.push(numberToHour(i));
      }
      const minute = parseInt((Math.round(i * 100)).toString().substr(-2));
      if (minute >= 45)
        i = Math.floor(i) + 1;
      else
        i += 0.15
    }
    return arr
  }

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({
      time: 0,
      date: setTimeOnDate(d.fromISO(date), hourToNumber(event.target.value)).toUTC().toISO()
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values, { setSubmitting }) => {
      }}
    >
      {() => {
        return (
          <Form style={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                  <FlatPickerBar
                    handleDateChange={(value: Date) => {
                      handleChange({
                        date: d.fromJSDate(value).startOf("day").toUTC().toISO(),
                        time: 0
                      });
                    }}
                    label={dateTitle ? dateTitle : ""}
                    identifier={name}
                    name={name}
                    disabled={disabled}
                    placeholderValue={"Select Date.."}
                    value={getLocalizedDateFormat(country, date, DATE_TYPE.CONDENSED)}
                    minDate={props.minDate ? getLocalizedDateFormat(country, props.minDate, DATE_TYPE.CONDENSED) : getLocalizedDateFormat(country, d.now().startOf('day').toUTC().toISO(), DATE_TYPE.CONDENSED)}
                    maxDate={maxDate ? getLocalizedDateFormat(country, maxDate, DATE_TYPE.CONDENSED) : ''}
                    enableTime={false}
                    type={"simple-time-picker"}
                    required={required}
                    country={country}
                  />
              </Grid>
              <Grid item xs={11} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputField
                    required
                    select
                    label={timeTitle}
                    value={date ? d.fromISO(date).toFormat("HH:mm") : "08:00"}
                    InputProps={{
                      onChange: handleTimeChange
                    }}
                    disabled={disabled || !date}
                    fullWidth
                  >
                    {getTimesArray(timeRange.current.toTime).map((hour, index) => {
                      return (
                        <MenuItem value={hour}>
                          {d.fromFormat(hour, "HH:mm").toFormat("hh:mm a")}
                        </MenuItem>
                      );
                    })}
                  </InputField>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik >
  )
}
