import {
  Fab,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  TextField,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { TestDriveBookingStepper } from "../TestDriveStepper";
import { Form, Formik } from "formik";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { updateTestDriveBooking } from "../../../../../actions/testDriveBooking/actions";
import {
  TestDriveBookingSteps,
  testDriveExperienceOptions,
  TestDriveExperiences,
  TestDriveTypes,
  testDriveTypesOptions,
} from "../../../../../reducers/testDriveBooking/types";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useEffect, useState } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { GET_VEHICLE_AVAILABILITY } from "../../../../../graphql/bookings/getTestDriveVehicleAvailability";
import { SnackBarVariant } from "../../../../SnackbarWrapper/SnackbarWrapper";
import {
  formatGraphQLErrorMessage,
  getVehicleTypeIcon,
} from "../../../../utils";

import { DateTime as d } from "luxon";

import { useSnackBar } from "../../../../SnackBarContext/SnackBarContext";
import { CalendarTimeSlotSelector } from "./SlotPicker";
import { Skeleton } from "@material-ui/lab";
import { Storage } from "aws-amplify";
import { debounce } from "lodash";
import { getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";

const useStyles = makeStyles((theme) => ({
  imageBox: {
    width: "95%",
    height: "75%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  title: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 250,
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-input": { cursor: "pointer" },
  },
  text: { marginTop: theme.spacing(2), marginBottom: theme.spacing(0.5) },
  cardTitle: {
    paddingLeft: 0,
  },
  proceedIcon: {
    marginRight: theme.spacing(1),
  },
  proceedButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    borderRadius: 0,
  },
  cardRoot: {
    minHeight: 500,
  },
  cardMedia: {
    minWidth: "600px",
    minHeight: "300px",
    borderRadius: 6,
    objectFit: "contain",
  },
}));

interface IVehicleSlotSelection {
  testDriveType: TestDriveTypes;
  testDriveExperience: TestDriveExperiences;
  selectedSlot: string;
  selectedDate: string;
  vehicleId: string;
  driverId: string;
  calendarInitialSlot: number;
  extendedTestDriveDuration: number;
  slotId: string;
}

export interface IVehicleAvailability {
  timeSlot: string;
  vehicleId: string;
  driverId: string;
  slotId: string;
}

const VehicleDetailsCard = ({ imageUrl = "", title = "" }) => {
  const classes = useStyles();
  const [image, setImage] = useState<string>(getVehicleTypeIcon("COUPE"));

  const getImage = async (image: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await Storage.get(image, config).then((result) => {
      return result;
    });
    const imgResponse = await fetch(file as string).then((res) => {
      return res;
    });
    if (!imgResponse.ok) {
      return;
    }
    setImage(file as string);
  };

  useEffect(() => {
    if (imageUrl) {
      getImage(imageUrl);
    }
  });

  return (
    <Card className={classes.cardRoot} elevation={0}>
      <CardMedia
        image={image}
        component="img"
        className={classes.cardMedia}
        title={title}
      />
      <CardContent>
        <Typography variant="h2" component="h2" className={classes.cardTitle}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

const initialVehicleSelection: IVehicleSlotSelection = {
  testDriveType: TestDriveTypes.STANDARD,
  testDriveExperience: TestDriveExperiences.CONSULTATION,
  selectedSlot: "",
  vehicleId: "",
  selectedDate: d.now().toUTC().toISO(),
  calendarInitialSlot: 0,
  driverId: "",
  extendedTestDriveDuration: 1,
  slotId: ""
};

export const VehicleConfirmation: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const [vehicleSlotSelection, setVehicleSelection] =
    useState<IVehicleSlotSelection>(initialVehicleSelection);
  const [openSlotPicker, setSlotPickerState] = useState<boolean>(false);
  const [vehicleAvailability, setVehicleAvailability] = useState<
    IVehicleAvailability[]
  >([]);

  const testDriveBookingState = useSelector(
    (state: IAppState) => state.testDriveBookingReducer
  );

  const appSetting = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );

  const extendedTestDriveMaxHours = useSelector(
    (state: IAppState) =>
      state.consumerWebsiteReducer.consumerWebsite.organisation
  ).extendedTestDriveMaxHours;

  const country =
    useSelector(
      (state: IAppState) =>
        state.consumerWebsiteReducer.consumerWebsite.organisation
    ).address.country || "";

  const [getTestDriveAvailablilty, { loading: availabilityLoading }] =
    useLazyQuery(GET_VEHICLE_AVAILABILITY, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.getTestDriveVehicleAvailability) {
          const vehicleAvailability =
            data.getTestDriveVehicleAvailability.timeSlots;
          let initialSlot = "",
            initialVehicleId = "",
            initialDriverId = "",
            initialSlotId = "";
          if (vehicleAvailability.length) {
            initialSlot = vehicleAvailability[0].timeSlot;
            initialVehicleId = vehicleAvailability[0].vehicleId;
            initialDriverId = vehicleAvailability[0].driverId;
            initialSlotId = vehicleAvailability[0].slotId;
          }
          setVehicleSelection({
            ...vehicleSlotSelection,
            selectedSlot: initialSlot,
            vehicleId: initialVehicleId,
            driverId: initialDriverId,
            slotId: initialSlotId,
            calendarInitialSlot: 0,
          });
          setVehicleAvailability(vehicleAvailability);
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR,
        });
      },
    });

  useEffect(() => {
    if (
      testDriveBookingState.availableVehicleIds &&
      appSetting.id &&
      testDriveBookingState.branchId
    ) {
      getTestDriveAvailablilty({
        variables: {
          queryDate: d.now().toUTC().toISO(),
          vehicleIds: testDriveBookingState.availableVehicleIds,
          branchId: testDriveBookingState.branchId,
        },
      });
    }
  }, [testDriveBookingState, getTestDriveAvailablilty, appSetting]);

  const nextStep = (testDriveValues: IVehicleSlotSelection) => {
    const testDriveTypeString = testDriveTypesOptions.filter(
      (types) => types.value === testDriveValues.testDriveType
    )[0].label;
    dispatch(
      updateTestDriveBooking({
        ...testDriveBookingState,
        ...testDriveValues,
        stepperData: {
          ...testDriveBookingState.stepperData,
          slot: `${d
            .fromISO(testDriveValues.selectedDate)
            .toLocaleString(d.DATE_FULL)} | ${testDriveValues.selectedSlot}`,
          experience: testDriveTypeString,
        },
        currentStep: TestDriveBookingSteps.PERSONAL_DETAILS,
        testDriveBookingDetails: {
          ...testDriveBookingState.testDriveBookingDetails,
          type: testDriveValues.testDriveType,
        },
        driverId: testDriveValues.driverId,
        slotId: testDriveValues.slotId
      })
    );
  };

  const prevStep = () => {
    dispatch(
      updateTestDriveBooking({
        ...testDriveBookingState,
        currentStep: TestDriveBookingSteps.VEHICLE_SELECTION,
      })
    );
  };

  const handleSlotPickerClose = () => {
    setSlotPickerState(false);
  };

  const delayedFetchAvailability = debounce(
    (extendedTestDriveDuration: number) => {
      getTestDriveAvailablilty({
        variables: {
          queryDate: d.now().toUTC().toISO(),
          vehicleIds: testDriveBookingState.availableVehicleIds,
          branchId: testDriveBookingState.branchId,
          extendedTestDriveDuration,
        },
      });
    },
    500
  );

  return (
    <Grid item container spacing={2}>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        alignItems="flex-start"
        justify="flex-start"
      >
        <Grid item>
          <IconButton aria-label="previous-step" onClick={prevStep}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h1" component={"h1"}>
            Select A Slot
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TestDriveBookingStepper />
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item container xs={12} sm={12} md={7} lg={7} xl={7}>
          <VehicleDetailsCard
            title={testDriveBookingState.stepperData.model}
            imageUrl={testDriveBookingState.vehicleImage}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="h2" component={"h2"}>
              Book A Test Drive
            </Typography>
          </Grid>

          <Grid item container xs={12}>
            <Formik
              validationSchema={""}
              enableReinitialize
              initialValues={vehicleSlotSelection}
              onSubmit={(values, { setSubmitting }) => {
                nextStep(values);
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <Form>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      {getLocalizedBookingSyntex(country)} Type
                    </Typography>
                    <Select
                      displayEmpty
                      value={formikProps.values.testDriveType}
                      onChange={(event) => {
                        setVehicleSelection({
                          ...formikProps.values,
                          extendedTestDriveDuration: 1,
                          testDriveType: event.target.value as TestDriveTypes,
                        });
                      }}
                      name="testDriveType"
                      className={classes.select}
                    >
                      {testDriveTypesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formikProps.values.testDriveType ===
                    TestDriveTypes.EXTENDED && (
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Typography variant="body1" className={classes.text}>
                        {getLocalizedBookingSyntex(country)} Duration (Hours)
                      </Typography>
                      <TextField
                        id={`extended-test-drive-hours-input`}
                        type="number"
                        name={"extendedTestDriveDuration"}
                        value={formikProps.values.extendedTestDriveDuration}
                        className={classes.select}
                        onChange={(event) => {
                          const hours = parseInt(event.target.value);
                          if (
                            (extendedTestDriveMaxHours &&
                              hours <= extendedTestDriveMaxHours) ||
                            !extendedTestDriveMaxHours
                          ) {
                            delayedFetchAvailability(
                              parseInt(event.target.value)
                            );
                            formikProps.setFieldValue(
                              "extendedTestDriveDuration",
                              parseInt(event.target.value)
                            );
                          }
                        }}
                      ></TextField>
                      {extendedTestDriveMaxHours && (
                        <Typography variant="body1">
                          Maximum {extendedTestDriveMaxHours} Hours Allowed
                        </Typography>
                      )}
                    </FormControl>
                  )}
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      Select Slot
                    </Typography>
                    {availabilityLoading ? (
                      <Skeleton variant="rect" animation="wave" height={40} />
                    ) : formikProps.values.selectedSlot ? (
                      <TextField
                        id={`-select`}
                        value={`${d
                          .fromISO(formikProps.values.selectedDate)
                          .toLocaleString(d.DATE_FULL)} | ${
                          formikProps.values.selectedSlot
                        }`}
                        onClick={() => setSlotPickerState(true)}
                        className={classes.select}
                        InputProps={{
                          readOnly: true,
                          style: { cursor: "pointer" },
                        }}
                      ></TextField>
                    ) : (
                      <TextField
                        id={`-select`}
                        value={`Vehicle Not Available on Selected Date`}
                        onClick={() => setSlotPickerState(true)}
                        className={classes.select}
                        InputProps={{
                          readOnly: true,
                          style: { cursor: "pointer" },
                        }}
                      ></TextField>
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Typography variant="body1" className={classes.text}>
                      Experience
                    </Typography>
                    <Select
                      id={`-select`}
                      displayEmpty
                      value={formikProps.values.testDriveExperience}
                      onChange={formikProps.handleChange}
                      className={classes.select}
                    >
                      {testDriveExperienceOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Grid item xs={12}>
                      <Fab
                        aria-label="proceed-with-vehicle-selection"
                        variant="extended"
                        size="medium"
                        type="submit"
                        style={{
                          backgroundColor:
                            !vehicleSlotSelection.selectedDate ||
                            !vehicleSlotSelection.selectedSlot
                              ? "#e0e0e0"
                              : "var(--primary-color)",
                        }}
                        className={classes.proceedButton}
                        disabled={
                          !vehicleSlotSelection.selectedDate ||
                          !vehicleSlotSelection.selectedSlot
                        }
                      >
                        <ArrowForwardIcon className={classes.proceedIcon} />
                        Proceed
                      </Fab>
                    </Grid>
                  </FormControl>
                  <CalendarTimeSlotSelector
                    dialogOpen={openSlotPicker}
                    loading={availabilityLoading}
                    handleDialogState={handleSlotPickerClose}
                    vehicleAvailability={vehicleAvailability}
                    initialSelectedSlot={formikProps.values.calendarInitialSlot}
                    fetchAvailability={(queryDateString: string) => {
                      getTestDriveAvailablilty({
                        variables: {
                          queryDate: d.fromISO(queryDateString).toUTC().toISO(),
                          vehicleIds: testDriveBookingState.availableVehicleIds,
                          branchId: testDriveBookingState.branchId,
                          extendedTestDriveDuration:
                            formikProps.values.extendedTestDriveDuration,
                        },
                      });
                    }}
                    setSlot={(
                      selectedDate: string,
                      vehicleId: string,
                      selectedSlot: string,
                      driverId: string,
                      slotId: string
                    ) =>
                      formikProps.setValues({
                        ...formikProps.values,
                        selectedDate,
                        selectedSlot,
                        vehicleId,
                        driverId,
                        slotId
                      })
                    }
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
