import React from 'react';
import styles from './index.module.css';
import { MapModule } from "./MapModule";
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';

const Tracking = (props: any) => {
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const logoSize = website.logoSize || 40
  const topBarHeight = logoSize > 50 ? logoSize + 20 : 70
  return (
    <div className={styles.screen}>
      <MapModule topBarHeight={topBarHeight}/>
    </div>
  );
}

export default Tracking

