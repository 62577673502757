import React, { useEffect, useState } from 'react'
import { BILLING_CYCLE_NAME, IAddonRequirementInput, IBooking } from '../../../../reducers/bookings/types'
import { Box, Container, Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { DateTime as d } from "luxon";
import InfoIcon from '@material-ui/icons/Info';
import { IAppState } from '../../../../store';
import { DATE_TYPE, getRetnalTaxAmount, getTaxDescription, isLongTermBooking, toCurrency } from '../../../utils';
import { getLocalizedBookingSyntex, getLocalizedDateFormat, getLocalizedTaxSyntex } from '../../../../utils/localized.syntex';
import { NuvvenDivider } from '../../Summary/Components/Divider';
import { RATE_TYPES } from '../../utils';

const useStyles = makeStyles((theme) => ({
  headlineBox: {
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  itemBox: {
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  itemBox1: {
    display: "flex",
    justifyContent: "space-between"
  },
  itemBox2: {
    backgroundColor: '#fff',
  },
  itemBox3: {
    backgroundColor: '#fff',
  },
  itemBox4: {
    backgroundColor: '#fff',
  },
}));


enum RentalTimeText {
  "hourly" = "hour",
  "daily" = "day",
  "weekly" = "week",
  "monthly" = "month"
}

interface IProps {
  booking: IBooking
}
const SubscriptionPaymentView: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const { booking } = props;
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const branches = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches);
  const authState = useSelector((state: IAppState) => state.authReducer);
  const authUser = useSelector((state: IAppState) => state.authReducer.user);
  const { locale, currency } = organisation;
  const { country } = website.organisation.address;
  const branch = branches.find((branch) => branch.id === booking.branchId);
  const [bookingDurationInDays, setBookingDurationInDays] = useState<any>();

  const taxes = branch && branch.addTaxOptIn ? branch.taxes.map((tax) => {
    return {
      code: tax.title,
      type: tax.type,
      rate: tax.rate,
      amount: tax.value,
      isVehicleGroupSpecific: tax.isVehicleGroupSpecific || false,
      vehicleGroups: tax.vehicleGroups || [],
      subdivisionOptIn: tax.subdivisionOptIn,
      subdivisionRate: tax.subdivisionRate,
      subdivisionType: tax.subdivisionType,
      subdivisionValue: tax.subdivisionValue
    }
  }) : [];

  let totalSubscriptionAmount = booking?.insuranceRate + 0;
  totalSubscriptionAmount += (booking.activeSubscription?.subscriptionAmount || 0) +
    (booking.activeSubscription?.enrollmentAmount || 0) +
    getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, booking.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1) +
    getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, booking.activeSubscription?.enrollmentAmount || 0, 1, 1)

  useEffect(() => {
    if (booking) {
      let bookingDuration;
      const longTermBooking = isLongTermBooking(booking);
      if (longTermBooking) {
        switch (booking.rateTypeName) {
          case BILLING_CYCLE_NAME.WEEKLY:
            if (booking.isRecurringBilling && booking.billingFrequency) {
              bookingDuration = 7 * booking.billingFrequency
            } else {
              bookingDuration = 7
            }
            setBookingDurationInDays(bookingDuration);
            break;
          case BILLING_CYCLE_NAME.MONTHLY:
            if (booking.isRecurringBilling && booking.billingFrequency) {
              let idealEndDate = d.fromISO(booking.pickupDateTime || "").plus({ months: booking.billingFrequency }).toUTC().toISO();
              bookingDuration = d.fromISO(idealEndDate).diff(d.fromISO(booking.pickupDateTime), "day").days;
              setBookingDurationInDays(bookingDuration);
            }
            break;
          default:
            break;
        }
      } else {
        bookingDuration = Math.ceil(d.fromISO(booking.dropoffDateTime || "").diff(d.fromISO(booking.pickupDateTime || ""), ["days"]).days);
        setBookingDurationInDays(bookingDuration);
      }
    }
  }, [booking]);

  const getAddonRate = (addon: IAddonRequirementInput, rentalTime: number) => {
    let rate = addon.rate * addon.quantity;
    if (!addon.hasFixedRate && rentalTime) {
      rate = rate * rentalTime;
    }
    return rate;
  };

  return (
    <Container maxWidth="sm" >
      {/* Headline Box */}
      <Box className={classes.headlineBox}>
        <Typography variant="h4" component="h1" style={{ fontSize: 20 }}>
          Payment Overview
        </Typography>
      </Box>

      {/* Four Boxes Vertically Aligned */}
      <Grid container spacing={0} direction="column" style={{ background: "#f0efef", borderRadius: 10, padding: 20 }}>
        <Grid item xs={12}>
          <Box className={`${classes.itemBox} ${classes.itemBox1}`}>
            <Box>
              <Typography variant='h4' style={{ marginTop: 10, fontSize: 20 }}>{booking.activeSubscription?.vehicle?.make} {booking.activeSubscription?.vehicle?.model}</Typography>
              <Typography variant='h5' style={{ marginTop: 10 }}>Color: {booking.activeSubscription.vehicle?.colour}</Typography>
            </Box>
            <Box>
              <Typography variant='h4' style={{ marginTop: 10, fontSize: 16 }}>{getLocalizedBookingSyntex(country)} ref</Typography>
              <Typography variant='h5' style={{ marginTop: 10 }}>{booking.referenceNumber}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={`${classes.itemBox} ${classes.itemBox2}`}>
            <Typography variant='h4' style={{ fontSize: 20 }}>Schedule</Typography>
            <Box mt={1}></Box>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography variant='h5'> Start Date</Typography>
                <Box mt={1}></Box>
                <Typography variant='h4'>{getLocalizedDateFormat(country, booking.activeSubscription.startDate || "", DATE_TYPE.CONDENSED)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5'> End Date</Typography>
                <Box mt={1}></Box>
                <Typography variant='h4'>{getLocalizedDateFormat(country, booking.activeSubscription.endDate || "", DATE_TYPE.CONDENSED)}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={`${classes.itemBox} ${classes.itemBox3}`}>
            <Typography variant='h4' style={{ fontSize: 20 }}>Contract details</Typography>
            <Box mt={1}></Box>
            <Grid item xs={12} container>
              <Grid item xs={4}>
                <Typography variant='h5'> Plan</Typography>
                <Box mt={1}></Box>
                <Typography variant='h4'>{booking.activeSubscription?.subscription?.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h5'>Duration</Typography>
                <Box mt={1}></Box>
                <Typography variant='h4'>{booking.activeSubscription.duration} {booking.activeSubscription?.subscription?.rateTypeName === "monthly" ? "Month's" : "Week's"}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h5'>Swaps</Typography>
                <Box mt={1}></Box>
                <Typography variant='h4'>{booking.activeSubscription?.swapsAllowed}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={`${classes.itemBox} ${classes.itemBox4}`}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={5} container>
                <Typography style={{ fontSize: 16 }} variant={"h5"}>{"Total Cost"}</Typography>
              </Grid>
              <Grid item xs={7} container justify={"flex-end"} spacing={1}>
                <Grid item xs={12} container justify={"flex-end"}><Typography variant={"h4"}>{toCurrency(booking.activeSubscription.subscriptionAmount + (getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, booking?.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1)), currency, locale)}</Typography></Grid>
                <Grid item xs={12} container justify={"flex-end"}>
                  <Typography variant={"caption"}>{booking.activeSubscription.rateTypeName} {toCurrency(booking.activeSubscription.subscriptionAmount, currency, locale)}</Typography>
                </Grid>
                <Grid item xs={12} container justify={"flex-end"}>
                  <Typography variant='caption'>
                    <IconButton
                      style={{
                        padding: 0,
                        paddingRight: 10
                      }}
                    >
                      <Tooltip
                        title={
                          <React.Fragment>
                            {
                              getTaxDescription(taxes, currency, locale).length > 0 &&
                              getTaxDescription(taxes, currency, locale).map(title => {
                                return (
                                  <>
                                    <span>({title})</span><br></br>
                                  </>
                                )
                              })
                            }
                          </React.Fragment>
                        }
                        arrow
                        placement={"top"}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </IconButton>
                    {getLocalizedTaxSyntex(country || "")} : {toCurrency(getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, props.booking?.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1), currency, locale)}
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify={"flex-end"}>
                  <Typography variant={"caption"}>{"Enrollment Fee"} {toCurrency(booking.activeSubscription.enrollmentAmount, currency, locale)}</Typography>
                </Grid>
                <Grid item xs={12} container justify={"flex-end"}>
                  <Typography variant='caption'>
                    <IconButton
                      style={{
                        padding: 0,
                        paddingRight: 10
                      }}
                    >
                      <Tooltip
                        title={
                          <React.Fragment>
                            {
                              getTaxDescription(taxes, currency, locale).length > 0 &&
                              getTaxDescription(taxes, currency, locale).map(title => {
                                return (
                                  <>
                                    <span>({title})</span><br></br>
                                  </>
                                )
                              })
                            }
                          </React.Fragment>
                        }
                        arrow
                        placement={"top"}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </IconButton>
                    {getLocalizedTaxSyntex(country || "")} : {toCurrency(getRetnalTaxAmount(authUser?.customer?.taxExempted ? [] : taxes, props.booking?.activeSubscription?.enrollmentAmount || 0, 1, 1), currency, locale)}
                  </Typography>
                </Grid>
              </Grid>
              {
                booking.addonRequirements.length > 0 && (
                  <Grid item xs={12} container>
                    <Typography style={{ fontSize: 16 }} variant={"h5"}>Add-ons</Typography>
                  </Grid>
                )
              }
              {
                <Grid item xs={12}>
                  {
                    booking.addonRequirements.map((addon, index) => {
                      const updateAddonInput: IAddonRequirementInput = {
                        addon: addon.addon.id,
                        hasFixedRate: addon.hasFixedRate,
                        name: addon.name,
                        quantity: addon.quantity,
                        rate: addon.rate,
                        displayName: addon.displayName,
                        tax: addon.tax,
                        taxable: addon.taxable
                      }
                      let taxAmount = 0;
                      if (!authUser?.customer?.taxExempted) {
                        if (addon.hasFixedRate) {
                          if (addon.tax && addon.tax.value) {
                            taxAmount = ((addon.rate * addon.tax.value / 100) * addon.quantity);
                          }
                        } else {
                          if (addon.tax && addon.tax.value) {
                            taxAmount = ((addon.rate * addon.tax.value / 100) * addon.quantity * 1);
                          }
                        }
                      }
                      totalSubscriptionAmount += getAddonRate(updateAddonInput, 1) + taxAmount
                      return (
                        <Grid container item xs={12} spacing={1}>
                          <Grid container item xs={6}>
                            <Typography variant='h4'>{addon.displayName ? addon.displayName : addon.name}</Typography>
                          </Grid>
                          <Grid item xs={6} container justify={"flex-end"} spacing={1}>
                            <Typography variant="h4">{toCurrency(getAddonRate(updateAddonInput, 1) + taxAmount, currency, locale)}</Typography>
                          </Grid>
                          <Grid item xs={6} container>
                            <Typography variant='caption'>Quantity: {addon.quantity}</Typography>
                          </Grid>
                          <Grid item xs={6} container justify='flex-end'>
                            <Typography variant="caption">{addon.quantity} x {addon.hasFixedRate ? toCurrency(updateAddonInput.rate, currency, locale) : `(${1} ${RentalTimeText[booking.rateTypeName as RATE_TYPES]}${1 > 1 ? "s" : ""} x ${toCurrency(updateAddonInput.rate, currency, locale)})`}</Typography>
                          </Grid>
                          <Grid item xs={12} container justify='flex-end' alignItems='center'>
                            {
                              addon.tax && addon.tax.title && addon.tax.value && (
                                <IconButton
                                  style={{
                                    padding: 0,
                                    paddingRight: 10
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      <>
                                        <Typography>({`${addon.tax.title} ${addon.tax.value}%`})</Typography>
                                      </>
                                    }
                                    arrow
                                    placement={"right"}
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                </IconButton>
                              )
                            }
                            {getLocalizedTaxSyntex(country || "")}  : {toCurrency(taxAmount, currency, locale)}
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>

              }
              {
                booking.insurancePolicy && (
                  <>
                    <Grid item xs={12} container>
                      <Typography style={{ fontSize: 16 }} variant={"h5"}>Insurance</Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      <Grid container item xs={6}>
                        <Typography variant='h4'>{booking.insurancePolicy.name}</Typography>
                      </Grid>
                      <Grid item xs={6} container justify={"flex-end"} spacing={1}>
                        <Typography variant="h4">{toCurrency(booking.insuranceRate * 1 * (1), currency, locale)}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              }
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={6} container>
                  <Typography style={{ fontSize: 16 }} variant={"h5"}>Total</Typography>
                </Grid>
                <Grid item xs={6} container justify={"flex-end"}>
                  <Typography variant={"h4"}>{toCurrency(totalSubscriptionAmount, currency, locale)}</Typography>
                </Grid>
              </Grid>
              {
                booking.depositDetails && (
                  <Grid item xs={12} container spacing={1}>
                    <Grid item xs={6} container alignItems='center'>
                      <Typography style={{ fontSize: 16 }} variant={"h4"}>Payable Deposit: </Typography>
                      <Typography style={{ fontSize: 16, marginLeft: 10 }} variant={"h5"}>{toCurrency(booking.depositDetails.amount, currency, locale)}</Typography>
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )

}

export default SubscriptionPaymentView
