import { useLazyQuery } from "@apollo/client";
import { CircularProgress, InputAdornment, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_CREDIT_NOTES } from "../../../../graphql/creditNotes/getCreditNotesQuery";
import { IAppState } from "../../../../store";
import { StyledInput } from "../Invoices";
import styles from "./index.module.css";
import SearchIcon from "@material-ui/icons/Search";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { StyledTableCell, StyledTableRow } from "../../../StyledTable";
import { DATE_TYPE, toCurrency } from "../../../utils";
import { useHistory } from "react-router-dom";
import { ICreditNote } from "../../../../reducers/bookings/types";

const CreditNotes: React.FC = () => {
  const history = useHistory();
  let [availableBalance, setAvailableBalance] = useState(0);
  const [filters, setFilters] = useState<{
    creditNoteRef?: string;
    creditNoteStatuses?: string[];
  }>({ creditNoteRef: "", creditNoteStatuses: [] });
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const [creditNotes, setCreditNotes] = useState<ICreditNote[]>([]);
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const { currency, locale } = website.organisation;
  const { country } = website.organisation.address;
  
  const [getCreditNotes, { loading, data: creditNotesData, error }] = useLazyQuery(
    GET_CREDIT_NOTES,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (user) {
      getCreditNotes({
        variables: {
          businessCustomerId: user.businesscustomers?.id || undefined,
          customerId: user.customerId || undefined,
        },
      });
    }
  }, [user]);
  
  useEffect(()=>{
    setAvailableBalance(creditNotes.reduce(
      (sum, creditNote) => sum + (creditNote.availableAmount || 0),
      0
    ))
  }, [creditNotes])

  useEffect(() => {
    if (creditNotesData && creditNotesData.consumerCreditNotes) {
      setCreditNotes(creditNotesData.consumerCreditNotes);
    }
  }, [creditNotesData]);

  if (!creditNotes) {
    return <></>;
  }

  enum EmptyLabel {
    ACTIVE = "No Active Credit Notes Found!",
    EXHAUSTED = "No Exhausted Credit Notes Found!"
  }

  let filteredCreditNotes =
    filters && filters?.creditNoteStatuses?.length
      ? creditNotes.filter((creditNote) => {
        if (filters.creditNoteStatuses?.includes("ACTIVE") && creditNote.availableAmount) {
          return creditNote
        }
        if (filters.creditNoteStatuses?.includes("EXHAUSTED") && !creditNote.availableAmount) {
          return creditNote
        }
      })
      : creditNotes;
  filteredCreditNotes = filters.creditNoteRef
    ? filteredCreditNotes.filter((creditNote) =>
      creditNote.creditNoteRef
        .toLowerCase()
        .includes(filters.creditNoteRef?.toLowerCase() || "")
    )
    : filteredCreditNotes;

  return (
    <div className={styles.column}>
      <div className={styles.headerContainer}>
        <div className={styles.headBalContainer}>
          <Typography variant="h2" className="semi-bold">
            Credit Notes
          </Typography>
          {loading && (
            <CircularProgress
              size={20}
              thickness={5}
              style={{ marginLeft: 10 }}
            />
          )}
          <Typography variant="h4" className="semi-bold" style={{paddingLeft: "40px", paddingRight: "20px"}}>
            Available Balance: {toCurrency(availableBalance, currency, locale)}
          </Typography>
        </div>
        <div className={styles.filters}>
          <div className="margin-right">
            <StyledInput
              size="small"
              placeholder="Search Credit Note Id"
              value={filters.creditNoteRef}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, creditNoteRef: e.target.value }))
              }
              InputProps={{
                style: {
                  padding: "0 0 0 8px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ opacity: 0.4 }} fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex" style={{height: 34}}>
          <button
            className={`${styles.button} ${!filters.creditNoteStatuses && styles.selected
              }`}
            onClick={() =>
              setFilters((prev) => ({ ...prev, creditNoteStatuses: [] }))
            }
          >
            All
          </button>
          <button
            className={`${styles.button} ${styles.unpaid} ${(filters.creditNoteStatuses?.includes("ACTIVE") &&
              styles.selected)
              }`}
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                creditNoteStatuses: [
                  "ACTIVE"
                ],
              }))
            }
          >
            Active
          </button>
          <button
            className={`${styles.button} ${styles.paid} ${filters.creditNoteStatuses?.includes("EXHAUSTED") &&
              styles.selected
              }`}
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                creditNoteStatuses: ["EXHAUSTED"],
              }))
            }
          >
            Exhausted
          </button>
          </div>
        </div>
      </div>
      {!loading && (
        <div className="margin-top padding-top">
          <TableContainer style={{ maxHeight: "100%" }} className="row-hover">
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Credit Note Id</StyledTableCell>
                  <StyledTableCell>{`${getLocalizedBookingSyntex(country)} Ref`}</StyledTableCell>
                  <StyledTableCell>Date Generated</StyledTableCell>
                  <StyledTableCell>Total</StyledTableCell>
                  <StyledTableCell>Amount Refunded</StyledTableCell>
                  <StyledTableCell>Amount Remaining</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCreditNotes.length ? (
                  filteredCreditNotes.map((creditNote, index) => (
                    <StyledTableRow
                      key={index}
                      onClick={() =>
                        history.push(`/account/credit-notes/${creditNote.id}`)
                      }
                    >
                      <StyledTableCell>{creditNote.creditNoteRef}</StyledTableCell>
                      <StyledTableCell>
                        {creditNote.booking ? creditNote.booking.referenceNumber : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {getLocalizedDateFormat(country, creditNote.createdAt, DATE_TYPE.EXPANDED, creditNote.booking?.branch?.timeZone)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {toCurrency(
                          creditNote.totalAmount,
                          currency,
                          locale
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {toCurrency(creditNote.refundedAmount, currency, locale)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {toCurrency(creditNote.availableAmount, currency, locale)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableCell colSpan={20}>
                    <Typography style={{ textAlign: "center", opacity: 0.5 }}> "Sorry, no matching records found!"</Typography>
                  </StyledTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

export default CreditNotes;