import { CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, Grid, makeStyles, MenuItem, TextField, Theme, Typography } from "@material-ui/core";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { IAppState } from "../../../../store";
import * as UUID from "uuid";
import { getSignedUrl } from "../../../../utils/getSignedUrl";
import { DragDropImages } from "./DragDropImages";
import { IFrogDiagram } from "./VehicleDamage/FrogDiagrams";
import { ImageView } from "./ImageView";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { useMutation } from "@apollo/client";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { ApolloError } from "apollo-boost";
import { formatGraphQLErrorMessage } from "../../../utils";
import dayjs from "dayjs";
import { SimpleDateTimePicker } from "../../../SimpleDateTimePicker/intex";
import _ from "lodash";
import { CONSUMER_CREATE_VEHICLE_DAMAGE_INFO } from "../../../../graphql/vehicles/createDamageVehicleMutation";
import { DateTime as d } from "luxon";
import { DamageTypes, IDamage, INewVehicleDamage, VehicleDamageStatus } from "../../../../reducers/bookings/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    blackBackButton: {
      background: "#404040",
      color: '#ffffff',
      '&:hover': {
        background: "#292929"
      }
    },
    doneButton: {
      background: "#FFC700",
      color: '#ffffff',
      '&:hover': {
        background: "#F78500"
      }
    },


  }),
);

const initialVehicleDamage = {
  circumstance: "BOOKING",
  bookingRef: "",
  bookingReferenceNumber: "",
  incidentDate: "",
  reportedBy: "",
  frogDiagram: "",
  estimatedCost: 0,
  status: VehicleDamageStatus.DUE,
  estimatedRepairDate: "",
  vehicleId: "",
  approvalStatus: "",
  damages: []
}

interface IProps {
  title: string;
  handleClose: () => void;
  open: boolean;
  images?: (images: string[]) => void;
  svg: any;
  values?: IDamage;
  index?: number | null;
  vehicleId?: string;
  bookingReferenceNumber?: string;
  frogDiagram: string;
  licencePlate: string;
  bookingId: string | undefined;
  pickupDateTime: string;
  dropoffDateTime: string;
}



interface IDateTime {
  date: string;
  time: number;
}

export const svgHeight = 400;
export const coordinateOffset = 50;

const damageKind = {
  "interior damage": [
    { label: 'Broken', value: 'BROKEN' },
    { label: 'Tear', value: 'TEAR' },
    { label: 'Burnt', value: 'BURNT' },
    { label: 'Cracked', value: 'CRACKED' },
    { label: 'Gum', value: 'GUM' },
    { label: 'Spill', value: 'SPILL' },
    { label: 'Other', value: 'OTHER' },
  ],
  "exterior damage": [{ label: 'Scratch', value: 'SCRATCH' },
  { label: 'Dent', value: 'DENT' },
  { label: 'Chip', value: 'CHIP' },
  { label: 'Scuff', value: 'SCUFF' },
  { label: 'Other', value: 'OTHER' }]
};

const damageArea = [
  { label: 'Dashboard', value: 'Dashboard' },
  {
    label: 'Steering-Wheel',
    value: 'Steering-Wheel',
  },
  { label: 'Seat', value: 'Seat' },
  { label: 'Carpet', value: 'Carpet' },
];


export const AddDamage: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const svgRef = useRef<any>(null);
  const Website = useSelector((state: IAppState) => state.consumerWebsiteReducer);
  const organisation = Website.consumerWebsite.organisation;
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [damagesValues, setDamageValues] = useState<INewVehicleDamage>(
    initialVehicleDamage
  );
  const [openDragDrop, setOpenDragDrop] = useState<boolean>(false);
  const [imageType, setImageType] = useState<string>("");
  const [images, setImages] = useState<string[]>([]);
  const [damageCoordinate, setDamageCoordinate] = useState<any>();
  const [svg, setSvg] = useState<IFrogDiagram>();
  const [consumerCreateDamage, { loading: createDamageLoading }] = useMutation(CONSUMER_CREATE_VEHICLE_DAMAGE_INFO, {
    onCompleted: (data) => {
      props.handleClose()
      snackbar({
        message: "Damage Information saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const damageSchema = Yup.object().shape({
    damages: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Title is required."),
        damageKind: Yup.string().required("Damage Kind is required."),
        descriptions: Yup.string().required("Description is required."),
        images: Yup.array().of(Yup.string().required()).min(1, "Damage image is required.")
      })
    )
  });

  useEffect(() => {
    if (props.svg && props.values && props.values.location) {
      const location = {
        x: props.values.location.x + coordinateOffset,
        y: props.values.location.y + coordinateOffset
      }
      setSvg(props.svg);
      setDamageValues({
        ...damagesValues,
        bookingRef: props.bookingId || "",
        reportDate: dayjs().toISOString(),
        bookingReferenceNumber: props.bookingReferenceNumber,
        frogDiagram: props?.frogDiagram,
        vehicleId: props.vehicleId || "",
        licencePlate: props.licencePlate || "",
        estimatedCost: 0,
        damages: [props?.values]
      })

      if (props.values?.damageType === DamageTypes.EXTERIOR) {
        addMark(location, props.svg.exterior.markerRadius, props.svg.exterior.markerFont)
      } else if (props.values?.damageType === DamageTypes.INTERIOR) {
        addMark(location, props.svg.interior.markerRadius, props.svg.interior.markerFont)
      }
    }
  }, [props.values])

  const handleOpenDragDrop = (type: string) => {
    setImageType(type);
    setOpenDragDrop(true);
  };
  const handleCloseDragDrop = () => {
    setOpenDragDrop(false);
  };
  const handleFormSubmit = (values: any) => {
    let updatedVehicleDamage = _.cloneDeep({ ...values });
    const { bookingReferenceNumber, licencePlate, ...restUpdatedVehicleDamage } = updatedVehicleDamage;
    consumerCreateDamage({
      variables: {
        vehicleId: restUpdatedVehicleDamage?.vehicleId,
        vehicledamage: restUpdatedVehicleDamage
      }
    })
  }

  const addMark = (damage: any, markerRadius: number, markerFont: number) => {

    const xVal = parseInt(damage.x);
    const yVal = parseInt(damage.y);
    const mark = (<g id={`damage-mark`} style={{ cursor: "pointer" }}>
      <circle cx={`${xVal}`} cy={`${yVal}`} r={`${markerRadius}`} fill="red" stroke-width="0.5" />
      <text text-anchor="middle" x={`${xVal}`} y={`${yVal}`} dy="0.30em" font-size={`${markerFont}em`} stroke-width="0.8" stroke="white" fill="white">
      </text>
    </g>);
    setDamageCoordinate(mark);
  }


  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll="paper"
      aria-labelledby="max-width-dialog-title"
      maxWidth={"lg"}
    >
      <Formik
        enableReinitialize
        initialValues={damagesValues}
        validationSchema={damageSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values)
          setSubmitting(false)
        }}
      >
        {formikProps => (
          <Form>
            <DialogTitle style={{ padding: '16px 35px' }} id="max-width-dialog-title">
              <Typography variant="h4" style={{ fontWeight: 700, fontSize: 16 }}>
                {props.title}
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid container item xs={6} md={6} lg={3}>
                  <Field
                    component={TextField}
                    label="Circumstance"
                    name={"circumstance"}
                    disabled
                    value={damagesValues.circumstance}
                    fullWidth
                    inputProps={
                      { readOnly: true, }
                    }
                  >
                  </Field>
                </Grid>
                <Grid container item xs={6} md={6} lg={3}>
                  <Field
                    component={TextField}
                    label="Registration Number"
                    name={"licencePlate"}
                    disabled
                    value={props.licencePlate}
                    fullWidth
                    inputProps={
                      { readOnly: true, }
                    }
                  >
                  </Field>
                </Grid>
                <Grid container item xs={6} md={4} lg={3}>
                  <Field
                    name={"damages[0].title"}
                    inputProps={{
                      value: formikProps.values.damages[0]?.title,
                      onChange: (
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setDamageValues((val) => {
                          const updatedDamages = [...val.damages];
                          updatedDamages[0] = {
                            ...updatedDamages[0],
                            title: event.target.value
                          };
                          return {
                            ...val,
                            damages: updatedDamages
                          };
                        });
                      }
                    }}
                    InputProps={{ maxLength: 30 }}
                  >
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <TextField
                          {...field}
                          error={touched && Boolean(error)}
                          placeholder="eg. NUVV120"
                          label="Title"
                          required
                          fullWidth
                        >
                        </TextField>
                        <ErrorMessage
                          name={`damages[0].title`}
                          component="div"
                        >
                          {msg => <div className={"field-error-message"}>{msg}</div>}
                        </ErrorMessage>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid container item xs={6} md={4} lg={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      name={"damages[0].damageKind"}
                      fullWidth
                      required
                      type="text"
                      select
                      label={`Damage Kind`}
                      value={formikProps.values.damages[0]?.damageKind}

                      onChange={(event: any) => {
                        formikProps.setFieldValue("damages[0].damageKind", event.target.value)
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}>

                      {damageKind[formikProps.values?.damages[0]?.damageType]?.map((item: any, index: number) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                      <ErrorMessage
                        name={`damages[0].damageKind`}
                        component="div"
                      >
                        {msg => <div className={"field-error-message"}>{msg}</div>}
                      </ErrorMessage>
                    </Field>
                  </FormControl>
                </Grid>
                {formikProps.values.damages[0]?.damageType === DamageTypes.INTERIOR && (<Grid container item xs={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      name={"damages[0].damageArea"}
                      fullWidth
                      type="text"
                      select
                      label="Damage Area"
                      value={formikProps.values.damages[0].damageArea}
                      onChange={(event: any) => {
                        formikProps.setFieldValue("damages[0].damageArea", event.target.value)
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    >
                      {damageArea.map((item: any, index: number) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </FormControl>
                </Grid>)}
                <Grid container item xs={6} md={4} lg={3}>
                  <Field
                    component={TextField}
                    label="Reference Number"
                    name={"bookingReferenceNumber"}
                    disabled
                    value={props.bookingReferenceNumber}
                    fullWidth
                    inputProps={
                      { readOnly: true, }
                    }
                  >
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <SimpleDateTimePicker
                    classId="flatpickr-container"
                    date={formikProps.values.incidentDate}
                    handleChange={(date: IDateTime) => {
                      if (date && date.date) {
                        setDamageValues({
                          ...formikProps.values,
                          incidentDate: date.date
                        })
                      }
                    }}
                    required={true}
                    name={"incidentDate"}
                    dateTitle={"Incident Date"}
                    timeTitle={"Incident Time"}
                    minDate={
                      d.fromISO(props?.pickupDateTime).toUTC().toISO()
                    }
                    maxDate={
                      d.fromISO(props?.dropoffDateTime).toUTC().toISO() || ""
                    }
                  />
                </Grid>
                <Grid container item xs={12}>
                  <Field
                    name={"damages[0].descriptions"}
                    value={formikProps.values.damages[0]?.descriptions || damagesValues.damages[0].descriptions}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      formikProps.setFieldValue("damages[0].descriptions", event.target.value);
                    }}
                    inputProps={{ maxLength: 500 }}
                  >
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <>
                        <TextField
                          {...field}
                          error={touched && Boolean(error)}
                          placeholder="eg. NUVV120"
                          label="Damage Detail"
                          multiline
                          rows={3}
                          required
                          fullWidth
                        >
                        </TextField>
                        <ErrorMessage
                          name={`damages[0].descriptions`}
                          component="div"
                        >
                          {msg => <div className={"field-error-message"}>{msg}</div>}
                        </ErrorMessage>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid container item xs={12} >
                  <div className={classes.root}>
                    <Grid container item xs={12} sm={12}>
                      <Grid container item xs={12} sm={6}>
                        <Grid item xs={12} sm={12}>
                          {formikProps.values.damages[0]?.damageType === DamageTypes.EXTERIOR &&
                            (<svg xmlns="http://www.w3.org/2000/svg"
                              style={{ height: `${svgHeight}px`, marginBottom: "20px" }}
                              ref={svgRef}
                              onClick={event => {
                                const rect = svgRef.current.getBoundingClientRect();
                                const location = {
                                  x: (event.clientX - rect.left) * props.svg?.exterior.width / rect.width,
                                  y: (event.clientY - rect.top) * props.svg?.exterior.height / svgHeight
                                };
                                addMark(location, props.svg.exterior.markerRadius, props.svg?.exterior.markerFont)
                                formikProps.setFieldValue("damages[0].location", { x: location.x - coordinateOffset, y: location.y - coordinateOffset });
                              }}
                              viewBox={`0 0 ${props.svg?.exterior.width} ${props.svg?.exterior.height}`}>
                              {props.svg?.exterior.svg}
                              {damageCoordinate}
                            </svg>)
                          }
                          {formikProps.values.damages[0]?.damageType === DamageTypes.INTERIOR && props.svg?.interior.svg &&
                            (<svg xmlns="http://www.w3.org/2000/svg"
                              style={{ height: `${svgHeight}px`, marginBottom: "20px" }}
                              ref={svgRef}
                              onClick={event => {
                                const rect = svgRef.current.getBoundingClientRect();
                                const location = {
                                  x: (event.clientX - rect.left) * props.svg?.interior.width / rect.width,
                                  y: (event.clientY - rect.top) * props.svg?.interior.height / svgHeight
                                };
                                addMark(location, props.svg?.interior.markerRadius, props.svg?.interior.markerFont)
                                formikProps.setFieldValue("damages[0].location", { x: location.x - coordinateOffset, y: location.y - coordinateOffset });
                              }}
                              viewBox={`0 0 ${props.svg?.interior.width} ${props.svg?.interior.height}`}>
                              {props.svg?.interior.svg}
                              {damageCoordinate}
                            </svg>)}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid xs={12} sm={12}>
                          <Fab
                            className="interiorImageButton"
                            variant="extended"
                            size="medium"
                            onClick={() => handleOpenDragDrop("damageImages")}
                            disabled={(organisation.maxImagesPerDamage && organisation.maxImagesPerDamage <= images.length ? true : false)}
                          >
                            {organisation.maxImagesPerDamage === 1 ? 'Add Image' : 'Add Image(s)'}
                          </Fab>
                          <ErrorMessage
                            name={`damages[0].images`}
                            component="div"
                          >
                            {msg => <div className={"field-error-message"}>{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
                          {images && images.length > 0 && (
                            <ImageView images={images} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <DragDropImages
                  open={openDragDrop}
                  handleCloseDragDrop={handleCloseDragDrop}
                  filesLimit={organisation.maxImagesPerDamage || 1}
                  handleSave={async (files: any) => {
                    if (imageType === "damageImages") {
                      if (_e_) {
                        const client = _e_;
                        const filePromises = [];
                        const newImages: any = [];
                        const imagesView: any[] = [];
                        if (files) {
                          for (var i = 0; i < files.length; i++) {
                            const name = files[i].name;
                            const lastDot = name.lastIndexOf('.');
                            const ext = name.substring(lastDot + 1);
                            const key = `${Website.consumerWebsite.tenantId}/${UUID.v4()}.${ext}`;
                            const promise = client
                              .add({
                                name: key,
                                file: files[i],
                              });
                            newImages.push(key);
                            const file = await getSignedUrl(key);
                            imagesView.push(file);
                            filePromises.push(promise);
                          }
                          handleCloseDragDrop();
                          Promise.all(filePromises).then(() => {
                            setDamageValues({
                              ...formikProps.values,
                              damages: [{
                                ...formikProps.values.damages[0],
                                images: [...newImages]
                              }]
                            })
                            setImages([...imagesView]);
                          }).catch((err) => console.log(err));
                        }
                      }
                    }
                  }}
                />
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: '20px 35px' }}>
              <div style={{ display: 'flex', flex: 1 }}>
              </div>
              <Fab
                className={classes.blackBackButton}
                variant="extended"
                size="small"
                onClick={props.handleClose}
              >
                Cancel
              </Fab>
              <Fab
                className={classes.doneButton}
                variant="extended"
                size="small"
                type="submit"
              ><strong>{createDamageLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}Done</strong>
              </Fab>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>

  )
}