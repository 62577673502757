export enum TestDriveBookingSteps {
  WELCOME_PAGE = "WELCOME_PAGE",
  BRANCH_SELECTION = "BRANCH_SELECTION",
  VEHICLE_SELECTION = "VEHCILE_SELECTION",
  VEHICLE_CONFIRMATION = "VEHICLE_CONFIRMATION",
  PERSONAL_DETAILS = "PERSONAL_DETAILS",
  CONFIRMATION = "CONFIRMATION",
}

export enum TestDriveTypes {
  STANDARD = "STANDARD",
  EXTENDED = "EXTENDED",
}

export enum TestDriveExperiences {
  CONSULTATION = "CONSULTATION",
}

export const testDriveTypesOptions = [
  { value: TestDriveTypes.STANDARD, label: "Standard Test Drive" },
  { value: TestDriveTypes.EXTENDED, label: "Extended Test Drive" },
];

export const testDriveExperienceOptions = [
  {
    value: TestDriveExperiences.CONSULTATION,
    label: "Consultation during Test Drive",
  },
];

interface IStepperData {
  dealership: string;
  model: string;
  slot: string;
  experience: string;
}

interface IAvailableBranches {
  branchId: string;
  vehicleGroupId: string;
}

export interface ITestDriveBookingDetails {
  experience: TestDriveExperiences;
  type: TestDriveTypes;
}

export interface ITestDriveBookingState {
  vehicleGroupId: string;
  availableBranches: IAvailableBranches[];
  availableVehicleIds: string[];
  testDriveType: TestDriveTypes;
  branchId: string;
  vehicleId: string;
  selectedSlot: string;
  extendedTestDriveDuration: number;
  currentStep: TestDriveBookingSteps;
  stepperData: IStepperData;
  pickupLocation: string;
  vehicleImage: string;
  testDriveBookingDetails: ITestDriveBookingDetails;
  driverId: string;
  slotId: string;
  isBookingUpdate?: boolean;
  id?: string;
}
