import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Fab,
  CardContent,
  Card,
  CardActions,
  CardMedia,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { getVehicleTypeIcon } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  imageBox: {
    objextFit: "cover",
    width: "450px",
    height: "200px",
  },
  title: {
    minHeight: "65px",
  },
  proceedIcon: {
    marginLeft: theme.spacing(1),
  },
  cardRoot: {
    maxWidth: 345,
  },
}));

export const VehicleSelectionCard = ({
  imageUrl = "",
  title = "",
  onClick = () => {},
}) => {
  const classes = useStyles();
  const [image, setImage] = useState<string>(getVehicleTypeIcon("COUPE"));

  const getImage = async (image: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await Storage.get(image, config).then((result) => {
      return result;
    });
    const imgResponse = await fetch(file as string).then((res) => {
      return res;
    });
    if (!imgResponse.ok) {
      return;
    }
    setImage(file as string);
  };

  useEffect(() => {
    if (imageUrl) {
      getImage(imageUrl);
    }
  });

  return (
    <Card className={classes.cardRoot} elevation={0}>
      <CardMedia
        image={image}
        component="img"
        height="200"
        style={{ borderRadius: 6, objectFit: "contain" }}
        title={title}
      />
      <CardContent>
        <Typography variant="h2" component="h2">
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        <Fab
          aria-label="proceed-with-vehicle-selection"
          variant="extended"
          size="medium"
          style={{
            backgroundColor: "var(--primary-color)",
          }}
          onClick={onClick}
        >
          Select
          <ArrowForwardIcon className={classes.proceedIcon} />
        </Fab>
      </CardActions>
    </Card>
  );
};
