import { useLazyQuery } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  DefaultAddons,
  IAddonRate,
  IAddonType,
  ValetType
} from "./types";
import { CustomCard } from "./CustomCard";
import { InsuranceCard } from "./InsuranceCard";
import styles from './index.module.css';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { ValetCard } from "./ValetCard";
import { useSelector } from "react-redux";
import { IAddonRequirementInput, IAddressLocation, IBookingCreateInput, IInsuranceAddon, ISubscriptionInfo } from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { GET_INSURANCE_POLICIES } from "../../../../../graphql/insurancePolicy/getInsurancePolicies";
import { GET_AVAILABLE_ADDON_TYPES } from "../../../../../graphql/addOns/getAvailableAddOnsQuery";

interface IAddonsProps {
  bookingData: IBookingCreateInput;
  setBookingData: Dispatch<SetStateAction<IBookingCreateInput>>;
  selectedSubscriptionPlan: ISubscriptionInfo
}

export const AddonsSelection: React.FC<IAddonsProps> = (props) => {
  
  const [activatedAddons, setActivatedAddons] = useState<IAddonType[]>([]);
  const [selectedAddons, setSelectedAddons] = useState<
    IAddonRequirementInput[]
  >([]);
  const [insuranceAddOns, setInsuranceAddOns] = useState<IInsuranceAddon[]>([]);
  const { bookingData, setBookingData, selectedSubscriptionPlan } = props;
  const authState = useSelector((state: IAppState) => state.authReducer);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const [loadAddons, { loading: addonsLoading, data: addonsData }] = useLazyQuery(GET_AVAILABLE_ADDON_TYPES, {
    fetchPolicy: "network-only"
  });

  const [loadInsurancePolicies, { loading: insuranceLoading, data: insurancePolicyData }] = useLazyQuery(
    GET_INSURANCE_POLICIES,
    {
      fetchPolicy: "network-only"
    }
  );
  useEffect(() => {
    if (props.bookingData.branchId) {
      loadInsurancePolicies();
      loadAddons({
        variables: {
          branchId: props.bookingData.branchId,
          startDate: props.bookingData.pickupDateTime,
          endDate: props.bookingData.dropoffDateTime,
          bookingId: props.bookingData.id || "",
          rateTypeName: props.bookingData.rateTypeName,
          businessCustomer: props.bookingData.businessCustomer ? props.bookingData.businessCustomer : authState.user?.businesscustomers?.id
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!props.bookingData.addonRequirements.length && selectedSubscriptionPlan.subscription.addons?.length) {
      const addons: IAddonRequirementInput[] = []
      selectedSubscriptionPlan.subscription.addons.forEach(addon => {
        addons.push({
          addon: addon.id,
          quantity: 1,
          name: addon.name,
          hasFixedRate: true,
          rate: 0,
          displayName: addon.displayName,
          taxable: false,
          tax: {
            title: "",
            value: 0
          }
        })
      })
      if (addons.length) {
        setBookingData({
          ...bookingData,
          addonRequirements: [
            ...bookingData.addonRequirements,
            ...addons
          ]
        })
      }
    }
  }, [selectedSubscriptionPlan])

  useEffect(() => {
    if (addonsData?.consumerAddonTypes) {
      let filteredExcludedAddons: IAddonType[] = [];

      // Filter out included addons from the subscription plan
      if (selectedSubscriptionPlan?.subscription.addons?.length) {
        const includedAddonIds = selectedSubscriptionPlan.subscription.addons.map((addon) => addon.id);
        filteredExcludedAddons = addonsData.consumerAddonTypes.filter(
          (addon: IAddonType) => addon.id && !includedAddonIds.includes(addon.id)
        );
      } else {
        filteredExcludedAddons = addonsData.consumerAddonTypes;
      }

      const selectAddons: IAddonRequirementInput[] = [];

      // Further filter addons based on rates and conditions
      const filteredAddons = filteredExcludedAddons.filter((addon: IAddonType) => {
        const addonsRate = addon.recurringRate?.find(
          (rate: IAddonRate) => rate.rateTypeName === props.bookingData.rateTypeName
        );

        return (
          (addon.hasFixedRate && addon.fixedRate !== 0) ||
          (!addon.hasFixedRate && addonsRate?.rate !== 0) ||
          addon.name === DefaultAddons.VALET
        );
      });
      // Determine applicable addons and select those already present in booking requirements
      const applicableAddons = filteredAddons.filter((addon: IAddonType) => {
        const addonPresent = props.bookingData.addonRequirements.find(
          (addReq) => addReq.addon === addon.id
        );

        if (addon.isActivated && addonPresent) {
          selectAddons.push(addonPresent);
        }

        return addon.isActivated;
      });

      if (applicableAddons.length) {
        // Sort applicable addons alphabetically
        applicableAddons.sort((a: IAddonType, b: IAddonType) =>
          (a.name || "").localeCompare(b.name || "", undefined, { sensitivity: "base" })
        );

        // Move the 'VALET' addon to the front if it exists
        const valetIndex = applicableAddons.findIndex(
          (addon: IAddonType) => addon.name === DefaultAddons.VALET
        );

        if (valetIndex > 0) {
          const [valetAddon] = applicableAddons.splice(valetIndex, 1);
          applicableAddons.unshift(valetAddon);
        }

        // Update state with the final addons
        setActivatedAddons(applicableAddons);
        setSelectedAddons(selectAddons);
      }
    }
  }, [addonsData, selectedSubscriptionPlan, props.bookingData.rateTypeName, props.bookingData.addonRequirements]);

  // insurance policy data
  useEffect(() => {
    if (insurancePolicyData && insurancePolicyData.consumerInsurancePolicies) {

      const insuranceAddOns = [];
      for (
        let index = 0;
        index < insurancePolicyData.consumerInsurancePolicies.length;
        index++
      ) {
        const insurancePolicy = insurancePolicyData.consumerInsurancePolicies[index];
        const insuranceRate = insurancePolicy.insuranceRate.find(
          (iRate: any) => iRate.rateName === props.bookingData.rateTypeName
        );
        if (insuranceRate) {
          insuranceAddOns.push({
            ...insuranceRate,
            policyId: insurancePolicy.id,
            excess: insurancePolicy.excess,
            policyName: insurancePolicy.name,
            description: insurancePolicy.description
          });
        }
      }
      let filteredSubscriptionInsurancePolicies
      if (selectedSubscriptionPlan.subscription.insurancePolicy?.id) {
        const filteredInsuranceAddon = insuranceAddOns.find(insurance => insurance.policyId === selectedSubscriptionPlan.subscription.insurancePolicy?.id)
        filteredSubscriptionInsurancePolicies = []
        setBookingData({
          ...bookingData,
          insuranceName: filteredInsuranceAddon?.policyName,
          insurancePolicy: filteredInsuranceAddon?.policyId,
          insuranceRate: 0
        })
      } else {
        filteredSubscriptionInsurancePolicies = insuranceAddOns
      }
      setInsuranceAddOns(filteredSubscriptionInsurancePolicies);
    }
  }, [insurancePolicyData]);

  const getAddons = (
    addonType?: IAddonType,
    rate?: number,
    quantity?: number,
    valetType?: string
  ) => {
    if (addonType && addonType.id && quantity) {
      const addons = [...selectedAddons];
      const existingIndex = selectedAddons.findIndex(
        (addon) => addon.addon === addonType.id
      );
      if (existingIndex > -1) {
        addons[existingIndex] = {
          ...addons[existingIndex],
          quantity,
          rate: rate || 0,
        };
      } else {
        // add to selected addons
        if (!valetType || valetType.length == 0) {
          addons.push({
            addon: addonType.id,
            hasFixedRate: addonType.hasFixedRate,
            name: addonType.name,
            quantity,
            displayName: addonType.displayName,
            rate: rate || 0,
            taxable: addonType.taxable,
            tax: addonType.taxable && addonType.tax ? {
              title: addonType.tax.title,
              value: addonType.tax.value
            } : {
              title: "",
              value: 0
            }
          });
        }
        else {
          addons.push({
            displayName: valetType,
            addon: addonType.id,
            hasFixedRate: addonType.hasFixedRate,
            name: addonType.name,
            quantity,
            rate: rate || 0,
            taxable: addonType.taxable,
            tax: addonType.taxable && addonType.tax ? {
              title: addonType.tax.title,
              value: addonType.tax.value
            } : {
              title: "",
              value: 0
            }
          });
        }
      }
      return addons;
    } else {
      return selectedAddons.filter(
        (addon) => addon.addon !== addonType?.id
      );
    }
  };

  const onCustomAddonSelect = (
    addonType?: IAddonType,
    rate?: number,
    quantity?: number,
    additionalDrivers?: number
  ) => {
    const addons = getAddons(addonType, rate, quantity);
    let includedAddons: IAddonRequirementInput[] = []
    if (selectedSubscriptionPlan.subscription.addons?.length) {
      selectedSubscriptionPlan.subscription.addons.forEach(addon => {
        includedAddons.push({
          addon: addon.id,
          quantity: 1,
          name: addon.name,
          hasFixedRate: true,
          rate: 0,
          displayName: addon.displayName,
          taxable: false,
          tax: {
            title: "",
            value: 0
          }
        })
      })
    }
    setSelectedAddons(addons);
    setBookingData({
      ...bookingData,
      addonRequirements: [
        ...addons,
        ...includedAddons
      ]
    });
  };

  const onInsuranceAddonSelect = (insuranceAddOn?: string) => {
    if (insuranceAddOn) {
      const selectedInsurance = insuranceAddOns.find(
        (addOn) => addOn.policyId === insuranceAddOn
      );
      if (selectedInsurance) {
        setBookingData({
          ...bookingData,
          ...{
            insuranceRate: selectedInsurance.rate,
            excess: selectedInsurance.excess,
            insurancePolicy: selectedInsurance.policyId,
            insuranceName: selectedInsurance.policyName
          }
        });
      }
    } else {
      setBookingData({
        ...bookingData,
        ...{
          insuranceRate: 0,
          excess: 0,
          insurancePolicy: undefined,
          insuranceName: undefined
        }
      });
    }
  };

  const onCoiSelect = (flag: boolean) => {
    if (flag) {
      setBookingData({
        ...bookingData,
        isCoi: true,
        insurancePolicy: "",
        insuranceRate: 0
      })
    } else {
      setBookingData({
        ...bookingData,
        isCoi: false
      })
    }
  }


  if (addonsLoading || insuranceLoading) {
    return <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </div>
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12}>
        <Typography variant={"h3"} color='primary' align="center" style={{ fontSize: 16, fontWeight: 700, marginBottom: 20 }} >Select Add-Ons</Typography>
        {
          !insuranceAddOns.length && !activatedAddons.length && (
            <Grid container item xs={12}>
              <Typography variant="h4">No available add-ons found matching your search criteria</Typography>
            </Grid>
          )
        }
        {
          <Grid container spacing={1} style={{ paddingBottom: 100 }}>
            {insuranceAddOns.length > 0 && !user?.businesscustomers?.coiDetails && (
              <Grid item xs={12} md={6}>
                <div
                  className={styles.insuranceAddonCard}
                  style={bookingData.insurancePolicy ? {
                    borderColor: "var(--primary-color)",
                    backgroundColor: 'var(--accent-shade-light)'
                  } : {}}
                >
                  <InsuranceCard
                    insuranceAddOns={insuranceAddOns}
                    onSelect={onInsuranceAddonSelect}
                    selectedAddon={bookingData.insurancePolicy}
                    onCoiSelect={onCoiSelect}
                    isCoi={bookingData.isCoi}
                  />
                </div>
              </Grid>
            )}
            {activatedAddons.map((addonType: IAddonType, index: number) => {
              const selectedAddon = selectedAddons.find(
                (ao) => ao.addon === addonType.id
              );
              if (addonType.name === DefaultAddons.ADDITIONAL_DRIVER) {
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <div
                      className={styles.customAddonCard}
                      style={addonType.name === selectedAddon?.name ? {
                        borderColor: "var(--primary-color)",
                        backgroundColor: 'var(--accent-shade-light)'
                      } : {}}
                    >
                      <CustomCard
                        addon={addonType}
                        rateTypeName={bookingData.rateTypeName}
                        onSelect={onCustomAddonSelect}
                        selectedAddon={selectedAddon}
                      />
                    </div>
                  </Grid>
                )
              }
              else {
                if (addonType.availableQuantity) {
                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <div
                        className={styles.customAddonCard}
                        style={addonType.name === selectedAddon?.name ? {
                          borderColor: "var(--primary-color)",
                          backgroundColor: 'var(--accent-shade-light)'
                        } : {}}
                      >
                        <CustomCard
                          addon={addonType}
                          rateTypeName={bookingData.rateTypeName}
                          onSelect={onCustomAddonSelect}
                          selectedAddon={selectedAddon}
                        />
                      </div>
                    </Grid>
                  );
                }
              }
            })}
          </Grid>
        }
      </Grid>
    </Grid>
  );
};
