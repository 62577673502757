import { gql } from "@apollo/client";

export const GET_USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      active
      firstName
      lastName
      phoneNumber {
        phone
        country
      }
      customerId
      businesscustomers {
        id
        businessName
        status
        declinedReason
        registrationNumber
        VAT
        registeredAddress {
          street
          city
          fullAddress
          state
          zipcode
          country
        }
        tradingAddressSame
        tradingAddress {
          street
          city
          fullAddress
          state
          zipcode
          country
        }
        approvedDrivers {
          id
          firstName
          lastName
          email
          profilePicture
          phoneNumber {
            phone
            country
          }
          nationalInsuranceNumber
          profession
          license {
            licenseNumber
            dateOfExpiry
            authority
            endorsement
            images
            offences
            points
            dvlaVerificationStatus
          }
          documents {
            id
            documentKey
            documentName
            documentType
            expiryDate
            title
            createdAt
          }
        }
        billing {
          phoneNumber {
            phone
            country
          }
          email
          accountNumber
        }
        contact {
          firstName
          lastName
          email
          phoneNumber {
            phone
            country
          }
        }
        overrideNetPaymentTerms
        netPaymentTerms
        overrideInvoiceDate
        invoicingDay
        businessCreditData {
          creditScore
          creditDescription
          lastChecked
          companyId
        }
        coiDetails {
          policyName
          policyNumber
          provider
          expiryDate
          documentUrl
        }
      }
      customer {
        id
        firstName
        lastName
        dateOfBirth
        costCenters {
          name
          projectIds
        }
        location {
          street
          city
          fullAddress
          state
          zipcode
          country
        }
        email
        phoneNumber {
          phone
          country
        }
        status
        tenantId
        profilePicture
        nationalInsuranceNumber
        profession
        documents {
          id
          documentKey
          documentName
          title
          expiryDate
          documentType
          createdAt
        }
        notes {
          id
          description
          createdBy
          createdDate
        }
        showDeclinedNote
        license {
          licenseNumber
          dateOfExpiry
          authority
          endorsement
          images
          offences
          points
          dvlaVerificationStatus
        }
        approvedDrivers {
          id
          firstName
          lastName
          email
          profilePicture
          phoneNumber {
            phone
            country
          }
          nationalInsuranceNumber
          profession
          license {
            licenseNumber
            dateOfExpiry
            authority
            endorsement
            images
            offences
            points
            dvlaVerificationStatus
          }
          documents {
            id
            documentKey
            documentName
            documentType
            expiryDate
            title
            createdAt
          }
        }
        taxExempted
        creditSafeData {
          creditScore
          lastChecked
        }
      }
      email
      role
    }
  }
`;
