import { CircularProgress, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { getUploadedImageByKey, returnfileContentTypeAndExtention, getSpellingByCountry } from '../utils';
import styles from './index.module.css';
import * as UUID from "uuid";
import { useSnackBar } from '../SnackBarContext/SnackBarContext';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { SnackBarVariant } from '../SnackbarWrapper/SnackbarWrapper';

interface IProps {
  label: string;
  onUpload: (key: string, image: string) => void;
  id: string;
}

const ImageUpload: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [image, setImage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);

  const { country } = website.organisation.address;
  const uploadLicenceImage = async (
    e: any,
  ) => {
    try {
      const profilePicArr: any[] = [];
      const showUploadedImages: any[] = [];
      var filesArr = Array.prototype.slice.call(e.target.files);
      const file = filesArr[0];
      if (file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
        const { fileExtension, contentType } = returnfileContentTypeAndExtention(file);
        const uniqueId = UUID.v4();
        const key = `${uniqueId}.${fileExtension}`;
        if (_e_) {
          setLoading(true);
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                profilePicArr.push(key);
                snackbar({
                  message: `${getSpellingByCountry(country)} Image uploaded successfully`,
                  variant: SnackBarVariant.SUCCESS
                });
                const uploadedFile = await getUploadedImageByKey(key);
                if (uploadedFile) {
                  showUploadedImages.push(uploadedFile);
                }
                setImage(uploadedFile);
                setLoading(false);
                props.onUpload(key, uploadedFile)
              }
            });
        }
      } else {
        return snackbar({
          message: "Please only upload .jpeg/.jpg/.png image format!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err) {
      snackbar({
        message: err.message,
        variant: SnackBarVariant.ERROR
      });
    }
  };

  return (
    <div className={styles.dlImage}>
      {image && <img src={image} alt="licence" />}
      {loading && <CircularProgress size={18} />}
      <label htmlFor={props.id} style={{ opacity: image || loading ? 0 : 1, cursor: "pointer", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="body2">{props.label}</Typography>
      </label>
      <input accept="image/*;capture=camera" style={{ display: 'none' }} onChange={(e: any) => { uploadLicenceImage(e); e.target.value = null }} id={props.id} type="file" />
    </div>
  )
}

export default ImageUpload