
import { SetStateAction, useEffect, useState } from "react";
import { IFilter, IFilterOption } from "../FilterSection/FilterSection";
import styles from './index.module.css'
import { Typography } from "@material-ui/core";
interface IProps {
  searchFilters?: { [key: string]: string[] }
  filters: IFilter[];
  onChange: (filters: {[key: string]: string[]; }) => void
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilters: () => void;
}

export const FiltersBottomSheet = (props: IProps) => {
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [marked, setMarked] = useState<{ [key: string]: boolean }>({});
  const handleReset = () => {
    props.clearFilters();
    setMarked({})
  }
  const handleChange = (option: IFilterOption, filterId: string)  => {
    const newValues = { ...marked, [`${filterId} ${option.id}`]: !marked[`${filterId} ${option.id}`] };
    let filters: string[] = []
    const filterKeyLen = filterId.length
    Object.keys(newValues).map(
      (key: string) => {
        if (filterId !== key.slice(0, filterKeyLen))
          return;
        const oldKey = key.slice(filterKeyLen)
        if (newValues[key] === true)
          filters.push(oldKey.trim());
      }
      );
      setMarked({ ...newValues });
      props.onChange({ [filterId]: [...filters] });
    }
  
  return (
    <div className={styles.bottomSheet}>
      <div className={styles.bottomSheetHeader}>
        <Typography variant="subtitle1" style={{ fontWeight: 700 }} >Filters</Typography>
        <div className="flex">
        <Typography variant="subtitle1" style={{ fontWeight: 700, color: 'var(--brand-accent)', marginRight: 15}} onClick={() => {
          handleReset();
          props.onClick(false);
        }
          }>Reset</Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 700, color: 'var(--primary-color)' }} onClick={() => props.onClick(false)}>Close</Typography>
        </div>
      </div>
      <div className={styles.bottomSheetSelection}>
        <div className={styles.bottomSheetFilterList}>
          {props.filters.map((filter: IFilter, index: number) => (
            <div className={`${styles.bottomSheetFilter} ${selectedFilter == index ? styles.active : ''}`} onClick={() => setSelectedFilter(index)}>
              <Typography variant="h5" style={{ fontWeight: 500 }}>{filter.name}</Typography>
            </div>
          ))}
        </div>
        <div className={styles.filterSelectionWrapper}>
          <div className={styles.filterSelection}>
            {props.filters[selectedFilter] && <BottomSheetSingleFilters
            onChange={props.onChange}
            filter={props.filters[selectedFilter]}
            searchFilters={props.searchFilters}
            marked={marked}
            setMarked={setMarked}
            handleChange={handleChange}
          />}
          
          </div>
        </div>
      </div>
    </div>
  );
};

interface IFilterSection {
  searchFilters?: { [key: string]: string[] };
  disabled?: boolean;
  filter: IFilter;
  onChange: (filters: {[key: string]: string[]; }) => void;
  marked: { [key: string]: boolean };
  setMarked: React.Dispatch<SetStateAction<{[key: string]: boolean; }>>;
  handleChange: (option: IFilterOption, filterId: string) => void;
}

const BottomSheetSingleFilters = (props: IFilterSection) => {
  
  useEffect(() => {
    const options: { [key: string]: boolean } = {};
    props.filter.options.forEach((option: IFilterOption) => {
      if (
        typeof option.value === "boolean" ||
        typeof option.value === "undefined"
      ) { 
        options[`${props.filter.id} ${option.id}`] = option.value ? option.value : false;
      }
    });
    props.searchFilters?.[props.filter.id]?.forEach((OptionId: string) => {
      options[`${props.filter.id} ${OptionId}`] = true;
    })
    props.setMarked({ ...options });
    
  }, []);
  return (
        <>
          {props.filter.options.map((option: IFilterOption, index: number) => (
            <div 
            key={index}
            onClick={() => props.handleChange(option, props.filter.id)}
            className={`${styles.singleFilter} ${props.marked[`${props.filter.id} ${option.id}`] ? styles.activeFilter : ''}`}
            >
              {option.name}
            </div>
          ))}
        </>
      );
}