import React, { useState } from "react";
import styles from "./index.module.css";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import MapIcon from '@material-ui/icons/Map';
import PeopleIcon from '@material-ui/icons/People';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { AccountType, UserRole } from '../../../../reducers/auth/types';
import { getLocalizedBookingSyntex } from "../../../../utils/localized.syntex";

const SideBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const authState = useSelector((state: IAppState) => state.authReducer);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);;
  const { country } = website.organisation.address;

  if (!user) {
    return <>Please Login first</>
  }

  const toggleDrawer = (open:any) => (event:any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const sideBarContent = (
    <div className={styles.column}>
      <NavLink to="/account" exact activeClassName={styles.active} className={styles.navItem}>
        <AccountCircleIcon fontSize="inherit" />
        <span>Account</span>
      </NavLink>
      {(authState.accountType === AccountType.INDIVIDUAL || (authState.accountType === AccountType.BUSINESS && user.role !== UserRole.DRIVER)) && <NavLink to="/account/bookings" activeClassName={styles.active} className={`${styles.navItem}`}>
        <CheckCircleIcon fontSize="inherit" />
        <span>{`${getLocalizedBookingSyntex(country)}s`}</span>
      </NavLink>}
      {!website.organisation.closeGroupSharingEnabled && (authState.accountType === AccountType.INDIVIDUAL || (authState.accountType === AccountType.BUSINESS && user.role !== UserRole.DRIVER)) && <NavLink to="/account/invoices" activeClassName={styles.active} className={styles.navItem}>
        <CreditCardIcon fontSize="inherit" />
        <span>Invoices</span>
      </NavLink>}
      {!website.organisation.closeGroupSharingEnabled && (authState.accountType === AccountType.INDIVIDUAL || (authState.accountType === AccountType.BUSINESS && user.role !== UserRole.DRIVER)) && <NavLink to="/account/credit-notes" activeClassName={styles.active} className={styles.navItem}>
        <CreditCardIcon fontSize="inherit" />
        <span>Credit Notes</span>
      </NavLink>}
      {user.businesscustomers && <>
        <NavLink to="/account/vehicles" activeClassName={styles.active} className={styles.navItem}>
          <DriveEtaIcon fontSize="inherit" />
          <span>Vehicles</span>
        </NavLink>
            {user.role === UserRole.ADMIN_USER && <><NavLink to="/account/drivers" activeClassName={styles.active} className={styles.navItem}>
            <PeopleIcon fontSize="inherit" />
            <span>Drivers</span>
          </NavLink>
          <NavLink to="/account/tracking" activeClassName={styles.active} className={styles.navItem}>
            <MapIcon fontSize="inherit" />
            <span>Tracking</span>
          </NavLink>
        </>}
          </>
          }
    </div>
  );

  return (
    <>
      <Hidden smUp>
        <div className={styles.menuIcon}>
          <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon style={{color: 'white'}}/>
          </IconButton>
        </div>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          classes={{ paper: styles.drawerPaper }}
        >
          {/* <div className={styles.drawerHeader}>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </div> */}
          <List>
            <ListItem>
              {sideBarContent}
            </ListItem>
          </List>
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        {sideBarContent}
      </Hidden>
    </>
  )
}

export default SideBar